.weSellMetals {
    padding-bottom: 142px;
    @media (max-width: 1239px) {
        padding-bottom: 92px;
    }
    @media (max-width: 959px) {
        background: #f6f8f8;
        padding-top: 45px;
        padding-bottom: 50px;
        margin-bottom: 75px;
    }
    .container {
        max-width: 1320px;
        background: #f6f8f8;
        border-radius: 8px;
        padding: 49px 65px 60px 60px;
        box-sizing: border-box;
        @media (max-width: 1239px) {
            max-width: 960px;
            padding: 42px 30px 80px 30px;
        }
        @media (max-width: 959px) {
            max-width: 300px;
            padding: 0;
        }
    }
    h2 {
        margin-bottom: 39px;
        @media (max-width: 1239px) {
            margin-bottom: 26px;
        }
    }
    &__row {
        display: flex;
        justify-content: space-between;
        @media (max-width: 959px) {
            flex-direction: column;
        }
    }
    &__text {
        p.mob {
            display: none;
        }
        p {
            width: 500px;
            margin-bottom: 73px;
            @media (max-width: 1239px) {
                width: 440px;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                margin-bottom: 24px;
                br:nth-child(1),
                br:nth-child(2) {
                    display: none;
                }
            }
            @media (max-width: 959px) {
                display: none;

                &.mob {
                    display: block;
                    width: 300px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0;
                    text-align: left;
                    margin-bottom: 15px;
                    margin-top: 34px;
                }
            }
        }
    }
    &__buttonsRow {
        margin-left: -15px;
        @media (max-width: 1239px) {
            margin-left: 0;
        }
        @media (max-width: 959px) {
            button {
                width: 300px;
            }
            button:first-child {
                margin-bottom: 10px;
            }
        }
        button:first-child {
            margin-right: 30px;
        }
    }
}
