.spinner {
  opacity: 0.5;
  width: 30px;
  height: 30px;
  margin: 30px auto;
  border-radius: 50%;
  vertical-align: text-bottom;
  border: 4px solid #0072ff;
  border-right-color: transparent;
  animation: 0.75s linear infinite spinner-border;

  &.small {
    width: 20px;
    height: 20px;
    margin: 20px auto;
    border-width: 3px;
  }
}

@keyframes spinner-border {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}