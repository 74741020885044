.sellMetalsPage {
    &__info {
        h2 {
            margin-bottom: 71px;
            @media (max-width: 959px) {
                margin-bottom: 34px;
            }
        }
    }
    &__pricesBlock {
        padding-bottom: 150px;
        @media (max-width: 1239px) {
            padding-bottom: 100px;
        }
        @media (max-width: 959px) {
            background-color: #f6f8f8;
            padding-top: 45px;
            padding-bottom: 50px;
            margin-bottom: 75px;
        }
        .sellMetalsPage__text {
            & p {
                display: block;
                width: 500px !important;

                @media (max-width: 959px) {
                    margin-top: 10px;
                }
            }
        }

        .container {
            box-sizing: border-box;
            max-width: 1320px;
            background-color: #f6f8f8;
            border-radius: 8px;
            padding: 49px 60px 60px;

            @media (max-width: 1239px) {
                max-width: 960px;
                padding: 42px 30px 80px 30px;
            }
            @media (max-width: 959px) {
                max-width: 300px;
                padding: 0;
            }

            h2 {
                margin-bottom: 36px;
                @media (max-width: 1239px) {
                    margin-bottom: 25px;
                }
            }
            .metalPrices {
                margin-top: 3px;
                @media (max-width: 1239px) {
                    margin-bottom: 1px;
                }
            }
        }
    }
    &__row {
        display: flex;
        justify-content: space-between;
        column-gap: 20px;
        @media (max-width: 959px) {
            flex-direction: column;
        }
    }
    &__text {
        display: flex;
        flex-direction: column;

        p {
            width: 585px;
            display: none;
            @media (max-width: 1239px) {
                width: 440px;
                br {
                    display: none;
                }
            }
            @media (max-width: 959px) {
                max-width: 300px;
                display: none;
                &.mob {
                    display: block;
                    margin-top: 34px;
                }
            }
        }
    }
    &__buttonsRow {
        margin-top: auto;
        @media (max-width: 1239px) {
            margin-top: 24px;
            display: flex;
            flex-direction: column;
        }
        @media (max-width: 959px) {
            margin-top: 15px;
        }
        button:first-child {
            margin-right: 20px;
            @media (max-width: 1239px) {
                margin-right: 0px;
                margin-bottom: 10px;
            }
        }
    }
}
