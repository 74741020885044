.aboutService{
  padding-bottom: 142px;
  padding-top: 142px;
  @media (max-width: 1239px) {
    padding-top: 92px;
    padding-bottom: 92px;
  }
  @media (max-width: 959px) {
      padding-top: 75px;
    padding-bottom: 75px;
  }
  .arrowButton {
    margin-top: 28px;
    @media(max-width: 1239px) {
      margin-top: 18px;
    }
    @media (max-width: 959px) {
      margin-top: 8px;
    }
  }
  &__other2{
      p{
        @media(max-width: 1239px) {
          width: 420px;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0;
          text-align: left;
          br {
            display: none;
          }
          @media (max-width: 959px) {
            width: 300px!important;
          }
        }

    }
    &:first-child{
      width: 585px;
      @media(max-width: 1239px){
        width: 440px;
      }
      @media (max-width: 959px){
        width: 300px;
      }
    }
    &:nth-child(2){
      width: 585px;
      @media(max-width: 1239px){
        width: 440px;

        p{
          margin-top: 44px;
        }
      }
      @media (max-width: 959px) {
        width: 300px;
      }

    }
    &:last-child{
      width: 100%;
    }

    p{
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      margin-top: 28px;
    }
    p.margin{
      width: 850px;
        @media(max-width: 1239px){
          width: 669px;
        }
    }

  }
  &__other2 {
    p {
      @media(max-width: 1239px) {
        width: 420px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
        margin-top: 18px;
        br {
          display: none;
        }
      }
    }
  }
  &__other1{

    @media (max-width: 1239px) {
      &:nth-child(1){
        p{
          margin-top: 18px;
        }
      }
      &:nth-child(2){
        p{
          margin-top: 44px;
        }
      }
      &:nth-child(3){
        p.margin{
          margin-top: 70px;
        }
      }
    }
    p{
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      margin-top: 29px;
      @media (max-width: 1239px) {

        br{
          display: none;
        }
      }
    }
    p.margin{
      margin-top: 55px;
    }

  }
  h2{
    margin-bottom: 44px;
    @media (max-width: 1239px) {
      margin-bottom: 25px;
    }
    @media (max-width: 1239px) {
      margin-bottom: 29px;
    }
  }
  &__p{
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 52px;
    width: 798px;

    @media (max-width: 1239px) {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      margin-bottom: 34px;
    }
    @media (max-width: 959px) {
      width: 300px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0;
      text-align: left;

      br{
        display: none;
      }
    }

    &-points{
      margin-bottom: 82px;
      @media (max-width: 1239px) {
        width: 670px;
        margin-bottom: 34px;
      }
      @media (max-width: 959px) {
        width: 300px;
      }
    }
    &-other3{
      @media (max-width: 1239px){
        width: 645px;

        br{
          display: none;
        }
      }

      @media (max-width: 959px){
        width: 300px;
      }
    }
    &-other2{
      width: 893px;
      margin-bottom: 52px;

      @media (max-width: 1239px){
        width: 670px;
      }
      @media (max-width: 959px){
        width: 300px;
      }
    }
  }

  &__pointsRow{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #10202726;
    @media (max-width: 959px) {
      flex-direction: column;
    }

    &-other2{
      flex-wrap: wrap;
    }
  }
  &__point{
    padding-bottom: 55px;
    padding-top: 15px;
    width: 380px;
    border-top: 1px solid #10202726;
    @media (max-width: 1239px) {
      width: 286px;
      padding-bottom: 24px;
    }
    @media (max-width: 959px) {
      width: 300px;

      p{
        margin-top: 9px!important;
        br{
          display: none;
        }
      }
    }

    h3.thin{
      opacity: 0.5;
      font-weight: 400;
      margin-bottom: 29px;
      @media (max-width: 1239px) {
        margin-bottom: 19px;
      }
      @media (max-width: 959px) {
        margin-bottom: 11px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;
      }
    }
  }
  &__row{
    display: flex;
    justify-content: space-between;
    padding-top: 54px;
    border-top: 1px solid #10202726;
    @media (max-width: 1239px) {
      padding-top: 40px;
    }
    @media (max-width: 959px) {
      padding-top: 34px;
      flex-direction: column-reverse;
    }

      p{
      width: 585px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      @media (max-width: 1239px) {
        width: 440px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;

      }
        @media (max-width: 959px) {
          width: 300px;
          margin-bottom: 35px;
        }
    }
  }
  &__square{
    display: flex;
    flex-direction: column;
    width: 278px;
    height: 278px;
    border: 1px solid #10202726;
    padding: 25px 28px 16px 30px;
    border-radius: 8px;
    box-sizing: border-box;
    @media (max-width: 959px) {
      width: 300px;
      height: 300px;
      padding: 25px 30px 16px 30px;
    }

    h3{
      width: 200px;
      font-weight: 400;
      opacity: 0.6;

      @media (max-width: 959px) {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;

      }
    }
    p{
      font-size: 30px;
      font-weight: 400;
      line-height: 39px;
      letter-spacing: 0;
      text-align: left;
      display: inline;
      width: 220px;
      margin-top: auto;
      @media (max-width: 959px) {
        margin-bottom: 0;
      }

      span{
        font-size: 50px;
        font-weight: 400;
        line-height: 65px;
        letter-spacing: 0;
        text-align: left;
        display: inline;
      }
    }
  }

  p.noMargin{
    @media (max-width: 1239px) {
      margin-top: 44px;
    }
  }
}