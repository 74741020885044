@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

#root {
    overflow: hidden;
}

body {
    font-family: "Inter", sans-serif;
    padding: 0;
    margin: 0;
    color: #102027;
}

a,
a:visited {
    text-decoration: none;
    color: initial;
    height: min-content;
}

p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0;
    text-align: left;
}

@media (max-width: 1239px) {
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
    }
}

h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0;
    text-align: left;
}

h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
}

h3.thin {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
}

h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0;
    text-align: left;
}

h1 {
    font-size: 45px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0;
    text-align: left;
}

h1,
h2,
h3,
h4,
h5,
p,
span {
    margin: 0;
    display: block;
    padding: 0;
}

button {
    border-radius: 0;
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

input {
    outline: none;
}

.container {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1239px) {
    .container {
        max-width: 900px;
    }
}

.blueButton {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    text-align: center;
    padding: 17px 30px;
    border-radius: 4px;
    background-color: #01579b;
    transition-duration: 300ms;
    color: white;
}

.blueButton:hover {
    background-color: #3177ae;
}

.grayButton {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    text-align: center;
    padding: 17px 30px;
    border-radius: 4px;
    border: 1px solid #10202733;
    transition-duration: 300ms;
}

.grayButton:hover {
    border-color: #102027;
}

.roundButton-light,
.roundButton-dark {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition-duration: 300ms;
}

.roundButton-light img,
.roundButton-dark img {
    transition-duration: 300ms;
}

.roundButton-light {
    border: 1px solid #10202733;
}

.roundButton-light:hover {
    background-color: #102027;
}

.roundButton-light:hover img {
    filter: brightness(0) invert(1);
}

.roundButton-dark {
    border: 1px solid #ffffff4d;
}

.roundButton-dark:hover {
    background-color: white;
    border: white;
}

.roundButton-dark:hover img {
    filter: brightness(1) invert(1);
}

.arrowButton {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.arrowButton label {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    text-align: left;
    color: #01579b;
    cursor: pointer;
}

.button {
    &--text {
        border: none;

        &:hover {
            color: #12609c;
        }

        &:active {
            color: #01579b;
        }
    }
}

@media (max-width: 959px) {
    .arrowButton label {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }
}

.arrowButton img {
    transition-duration: 300ms;
}

.arrowButton:hover img {
    margin-left: 5px;
}

.animatedInput {
    position: relative;
    width: min-content;
    cursor: pointer;
}

.animatedInput label {
    position: absolute;
    top: 15px;
    left: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
    opacity: 0.6;
    transition-duration: 300ms;
    cursor: pointer;
}

.animatedInput input {
    height: 55px;
    border: 0;
    border-bottom: 1px solid #10202726;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
    box-sizing: border-box;
    transition-duration: 300ms;
    cursor: pointer;
    background-color: transparent;
}

.animatedInput.error input {
    border-bottom: 1px solid #ff3d3d;
}

.animatedInput.error label {
    color: #ff3d3d;
}

.animatedInput p {
    position: absolute;
    top: 21px;
    right: 27px;
    cursor: pointer;
}

.animatedInput img {
    position: absolute;
    top: 22px;
    right: -2px;
    cursor: pointer;
}

.animatedInput input:focus,
.animatedInput input:not(:placeholder-shown) {
    padding-top: 21px;
    padding-bottom: 9px;
}

.animatedInput input:focus ~ label,
.animatedInput input:not(:placeholder-shown) ~ label {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    top: -4px;
}

.animatedCheckbox {
    display: flex;
    align-items: center;
    column-gap: 7px;
    cursor: pointer;

    .checkbox {
        width: 18px;
        height: 18px;
        border: 1px solid #01579b;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;

        svg {
            position: absolute;
            fill: white;
            font-size: 12px;
        }

        &.checked {
            background-color: #01579b;
        }
    }

    .checkbox-round {
        border-radius: 50%;

        svg {
            font-size: 10px;
        }
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0;
        text-align: left;
        opacity: 0.6;
    }
}

.page-enter {
    opacity: 0;
    transform: scale(1.1);
}

.page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
}

.page-exit {
    opacity: 1;
    transform: scale(1);
}

.page-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

@media (max-width: 960px) {
    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
    }
    h1 {
        font-size: 30px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
    }
    h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0;
        text-align: left;
    }
    h3 {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
    }
    h3.thin {
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0;
        text-align: left;
    }
    h4 {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
    }
    .container {
        max-width: 300px;
    }
    .blueButton {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0;
    }
    .grayButton {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0;
    }
    .animatedInput label {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
    }
    .animatedInput p {
        top: 19px;
    }
    .animatedInput img {
        top: 17px;
    }
    .animatedInput input {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
    }
    .animatedInput input:focus,
    .animatedInput input:not(:placeholder-shown) {
        padding-top: 19px;
        padding-bottom: 11px;
    }
}

.dropdown {
    position: absolute;
    padding: 2em;
    box-shadow: -1px 0px 10px rgba(0, 0, 0, 0.25);
    z-index: 10;
    background-color: #f6f8f8;
    margin-right: -2em;
    margin-top: 1.2em;
}
