.metalPrices{
  width: 555px;
  @media (max-width: 1239px) {
    width: 390px;
  }
  @media (max-width: 959px) {
    width: 300px;
  }
  &__namesRow{
    position: relative;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(16, 32, 39, 0.1);
    @media (max-width: 959px) {
      padding-bottom: 10px;
    }
    label{
      font-family: 'Roboto', serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      opacity: 0.6;

      &:nth-child(2){
        position: absolute;
        right: 256px;
        @media (max-width: 1239px) {
          right: 174px;
        }
        @media (max-width: 959px) {
          right: 131px;
        }
      }
      &:last-child{
        position: absolute;
        right: 0;
      }

      @media (max-width: 959px) {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0;
        text-align: right;
      }
    }
  }
  &__priceRow{
    position: relative;
    padding-top: 12px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(16, 32, 39, 0.1);
    width: 100%;

    @media (max-width: 959px) {
      padding-top: 15px;
    }
    .name{
      span{
        font-size: 30px;
        font-weight: 400;
        line-height: 39px;
        letter-spacing: 0;
        text-align: left;
        @media (max-width: 959px) {
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0;
          text-align: left;
        }
      }
      label{
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0;
        text-align: left;
        width: 100%;
        opacity: 0.5;
        margin-top: 2px;
        display: block;
        @media (max-width: 959px) {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0;
          text-align: left;
          margin-top: 1px;
        }
      }
    }
    .price{
      position: absolute;
      right: 0px;
      top: 12px;
      width: max-content;

      @media (max-width: 1239px) {
        right: 0px;
      }
      @media (max-width: 959px) {
        right: 0px;
        top: 15px
      }
      span{
        font-size: 30px;
        font-weight: 400;
        line-height: 39px;
        letter-spacing: 0em;
        text-align: right;
        @media (max-width: 959px) {
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0;
          text-align: left;
        }
      }
      label{
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0;
        text-align: right;
        width: 100%;
        opacity: 0.5;
        margin-top: 2px;
        display: block;
        &.mob{
          display: none;
        }
        @media (max-width: 959px) {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0;
          //text-align: left;
          margin-top: 1px;
          display: none;

          &.mob{
            display: block;
          }
        }
      }
    }
    .change{
      width: max-content;
      position: absolute;
      right: 0;
      top: 12px;
      @media (max-width: 1239px) {
        top: 15px
      }
      .changeRow{
        display: flex;
        align-items: center;

        &.green{
          span{
            color: #219653;
          }
          .triangle{
            border-color: transparent transparent #219653 transparent;
          }
        }
        &.red{
          span{
            color: #FF0000;
          }
          .triangle{
            border-color: transparent transparent #FF0000 transparent;
            transform: rotate(180deg);
          }
        }
        .triangle{
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 5px 5px 5px;
          border-color: transparent transparent #219653 transparent;
          margin-right: 12px;
        }
        span{
          font-size: 30px;
          font-weight: 400;
          line-height: 39px;
          letter-spacing: 0;
          text-align: right;
          @media (max-width: 959px) {
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0;
            text-align: left;
          }

        }
      }
      label{
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0;
        text-align: right;
        opacity: 0.5;
        margin-top: 2px;
        display: block;
        &.mob{
          display: none;
        }
        @media (max-width: 959px) {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0;
          text-align: left;
          display: none;

          &.mob{
            display: block;
          }
        }
      }
    }
  }

  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}