.services{
  display: flex;
  justify-content: space-between;
  @media (max-width: 959px) {
    &-solo{
      .services__title{
        h2{
          display: none;
        }
      }
    }
  }
  &__outer{
    margin-bottom: 150px;

    & > .container{
      h2{
        display: none;
        margin-bottom: 31px;
      }
    }
    @media (max-width: 1239px){
      margin-bottom: 100px;
    }
    @media (max-width: 959px){
      margin-bottom: 80px;
      padding-top: 75px;
      & > .container{
        h2{
          display: block;
        }
      }
    }
  }
  &__dots{
    display: none;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    @media (max-width: 959px) {
      display: flex;
    }
    .dot{
      width: 6px;
      height: 6px;
      background: #102027;
      border-radius: 50%;
      opacity: 0.3;
      margin-right: 20px;
    }
    .dot:last-child{
      margin-right: 0;
    }
    .dot.active{
      opacity: 1;
    }
  }
  @media (max-width: 1239px) {
    &__controlsRow{
      margin-top: 40px;
    }
  }
  @media(max-width: 1239px){
    min-width: calc(900px + ((100vw - 900px) / 2));


  }
  @media(max-width: 959px){
    min-width: calc(300px + ((100vw - 300px) / 2));
  }
  @media(max-width: 1239px){
    overflow: hidden;
    justify-content: flex-start;
    &::-webkit-scrollbar {
      display: none;
    }
    & {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
  &__h2-all{
    margin-bottom: 51px;
  }
  &-block{
    margin-bottom: 150px;
    @media (max-width: 1239px) {
      margin-bottom: 100px;
    }
  }
  .container-services{
    display: flex;
    @media (max-width: 1239px) {
      flex-direction: column;

      h2{
        margin-bottom: 31px;
      }
    }
    &_all{
      display: flex;
      flex-direction: column;
    }
  }
  &__title{
    width: 278px;
  }
  &__holder{
    display: flex;
    width: 894px;

    justify-content: space-between;

    &-all{
      width: 1202px;
    }
    @media (max-width: 959px) {
      width: calc(300px + (100vw - 300px) / 2);
    }
  }
  &__circle{
    width: 250px;
    height: 250px;
    position: absolute;
    right: -75px;
    top: 200px;
    background-color: white;
    opacity: 0.1;
    border-radius: 50%;
    transition-duration: 300ms;
    @media(max-width: 959px){
      top: 145px
    }
  }
  &__card{
    box-sizing: border-box;
    width: 278px;
    height: 500px;
    background-color: #102027;
    border-radius: 8px;
    position: relative;
    padding-top: 25px;
    padding-left: 30px;
    padding-right: 30px;
    overflow: hidden;
    transition-duration: 300ms;
    cursor: pointer;
    @media(max-width: 1239px){
      min-width: 287px;
      margin-right: 20px;
      padding-top: 26px;
      padding-left: 20px;
      padding-right: 32px;

      &:last-child{
        margin-right: calc(((100vw - 900px) / 2));
      }
    }
    @media(max-width: 959px){
      min-width: 290px;
      height: 450px;
      margin-right: 10px;

      p{
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;

      }
      &:last-child{
        margin-right: calc(((100vw - 300px) / 2));
      }
    }

    &:hover{
      background-color: #01579B;

      .services__circle{
        width: 280px;
        height:  280px;
        top: 186px;
        @media(max-width: 959px){
          top: 125px
        }

      }
      .roundButton-dark{
        background-color: white;

        img{
          filter: invert(1);
        }

      }
    }
    h3{
      color: white;
      margin-bottom: 17px;
    }
    p{
      width: 219px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      color: white;
      opacity: 0.6;

      @media(max-width: 959px){
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
        width: 233px;

        br{
          display: none;
        }
      }
    }
    &-image{
      position: absolute;
      right: 0;
    }
    .roundButton-dark{
      position: absolute;
      left: 30px;
      bottom: 30px;
      @media (max-width: 959px) {
        left: 20px;
        bottom: 20px;
      }
    }

    &-1{
      &-image{
        top: 199px;
        @media(max-width: 959px){
          top: 144px
        }
      }
    }
    &-2{
      &-image{
        top: 198px;
        @media(max-width: 959px){
          top: 143px
        }
      }
    }
    &-3{
      &-image{
        top: 188px;
        @media(max-width: 959px){
          top: 133px
        }
      }
    }
    &-4{
      margin-right: 10px;
      &-image{
        top: 202px;
        @media(max-width: 959px){
          top: 147px
        }
      }
    }
  }
}