#footer {
    width: 100%;
    border-top: 1px solid #1020271a;
}

.footer {
    &__firstLine {
        border-bottom: 1px solid #1020271a;
    }
    &__container {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        @media (max-width: 1239px) {
            max-width: 900px;
        }
        @media (max-width: 959px) {
            max-width: 300px;
        }
    }
    &__row-top {
        display: flex;
        padding-top: 55px;
        padding-bottom: 52px;
        @media (max-width: 959px) {
            flex-direction: column;
            padding-top: 46px;
            padding-bottom: 45px;
        }
    }
    &__links {
        display: flex;
        flex-direction: column;

        h4 {
            margin-bottom: 31px;
            @media (max-width: 959px) {
                margin-bottom: 21px;
            }
        }

        a {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
            color: #102027;
            margin-bottom: 22px;
            position: relative;
            width: max-content;
            @media (max-width: 959px) {
                margin-bottom: 14px;
            }
        }
        a::before {
            position: absolute;
            bottom: -4px;
            content: "";
            height: 1px;
            width: 0;
            background-color: #01579b;
            transition-duration: 300ms;
            opacity: 0;
        }

        a:hover:before {
            width: 100%;
            opacity: 1;
        }

        a:last-child {
            margin-bottom: 0;
        }
    }
    &__links:nth-child(2) {
        margin-left: 143px;
        margin-right: 143px;
        @media (max-width: 1239px) {
            margin-left: 63px;
            margin-right: 63px;
        }
        @media (max-width: 959px) {
            margin: 37px 0;
        }
    }
    &__logosCol {
        display: flex;
        flex-direction: column;
        margin-left: 137px;
        @media (max-width: 1239px) {
            margin-left: 64px;
        }
        @media (max-width: 959px) {
            margin-left: 0;
            margin-top: 42px;
        }
    }
    &__logosRow {
        margin-top: 5px;
        display: flex;
        width: 242px;
        justify-content: space-between;

        @media (max-width: 1239px) {
            width: 187px;
            img {
                height: 30px;
            }
        }
    }
    &__languages {
        display: flex;
        width: 104px;
        justify-content: space-between;
        margin-top: 127px;
        @media (max-width: 1239px) {
            margin-top: 137px;
        }
        @media (max-width: 959px) {
            margin-top: 44px;
        }

        button {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0;
            text-align: left;
            color: #102027;
            opacity: 0.5;
            &.active {
                opacity: 1;
            }
        }
    }

    &__secondLine {
        border-bottom: 1px solid #1020271a;
    }
    &__rowBottom {
        display: flex;
        padding-top: 30px;
        padding-bottom: 30px;
        align-items: center;
        @media (max-width: 959px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    &__social {
        display: flex;
        @media (max-width: 959px) {
            margin-bottom: 26px;
        }
    }
    &__socialLink {
        box-sizing: border-box;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 1px solid #10202733;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        transition-duration: 300ms;

        img {
            transition-duration: 300ms;
        }
        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background-color: #102027;
            border-color: #102027;

            img {
                filter: brightness(0) invert(1);
            }
        }
    }
    &__payMethods {
        display: flex;
        flex-direction: column;
        margin-left: 341px;
        @media (max-width: 1239px) {
            margin-left: 142px;
        }
        @media (max-width: 959px) {
            margin-left: 0;
            margin-bottom: 26px;
        }
    }
    &__caption {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0;
        text-align: left;
        color: #102027;
        opacity: 0.5;
    }
    &__deliveryMethods {
        display: flex;
        flex-direction: column;
        margin-left: 222px;
        @media (max-width: 1239px) {
            margin-left: 142px;
        }
        @media (max-width: 959px) {
            margin-left: 0;
        }
    }

    &__deliveryMethod {
        width: 100px;
        margin-top: 5px;
    }

    &__payRow {
        display: flex;
        align-items: center;
        margin-top: 5px;

        img {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__deliveryRow {
        display: flex;
        align-items: center;
        margin-top: 5px;
        img {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__thirdLine {
        display: flex;
        justify-content: center;
        padding-top: 34px;
        padding-bottom: 35px;
        @media (max-width: 959px) {
            padding-top: 26px;
            padding-bottom: 25px;
        }
    }
    &__credits {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: center;
        color: #102027;
        opacity: 0.5;
        @media (max-width: 959px) {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
        }
    }
}
