// NOTE установить ширину для экранов поменьше

.purchasePage {
    &__controlsRow {
        border-bottom: 1px solid rgba(16, 32, 39, 0.15);
    }
    &__breadcrumbRow {
        margin-top: 56px;
        margin-bottom: 45px;
        @media (max-width: 1239px) {
            margin-top: 36px;
            margin-bottom: 25px;
        }
        @media (max-width: 959px) {
            margin-top: 26px;
            margin-bottom: 29px;
        }

        label {
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0;
            text-align: left;
            opacity: 0.5;
            &:first-child {
                margin-right: 40px;
                @media (max-width: 959px) {
                    margin-right: 20px;
                }
            }
            @media (max-width: 959px) {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0;
                text-align: left;
            }
        }
    }
    &__titleRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 44px;
        @media (max-width: 1239px) {
            margin-bottom: 25px;
        }
        @media (max-width: 959px) {
            flex-direction: column;
            align-items: flex-start;

            h1 {
                margin-bottom: 23px;
            }
        }
        .grayButton {
            margin-right: 20px;
            @media (max-width: 959px) {
                margin-right: 10px;
            }
        }
        .grayButton.disabled {
            opacity: 0.5;
        }
        .blueButton.disabled {
            opacity: 0.5;
        }
    }
    &__controls {
        display: flex;
        justify-content: space-between;
        @media (max-width: 959px) {
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    &__steps {
        display: flex;
    }
    &__step,
    &__cart {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;

        position: relative;
        margin-bottom: 14px;
        @media (max-width: 959px) {
            width: max-content;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
        }
        &::before {
            position: absolute;
            bottom: -14px;

            content: "";
            height: 1px;
            width: 0;
            background-color: #01579b;
            transition-duration: 300ms;
            opacity: 0;
        }
        &:hover:before,
        &.active:before {
            width: 100%;
            opacity: 1;
        }
    }
    &__step {
        margin-right: 40px;
    }
    &__cart {
        @media (max-width: 959px) {
            display: none;
        }
    }
    &__step1 {
        padding-top: 60px;
        padding-bottom: 150px;
        @media (max-width: 1239px) {
            padding-top: 40px;
            padding-bottom: 100px;
        }
        @media (max-width: 959px) {
            padding-bottom: 80px;
        }

        .container {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            row-gap: 30px;
            column-gap: 30px;

            @media (max-width: 1239px) {
                row-gap: 20px;
            }

            @media (max-width: 959px) {
                flex-direction: column;
                row-gap: 0;
            }
        }
    }
    &__item {
        width: 380px;
        padding: 30px;
        box-sizing: border-box;
        background: #f6f8f8;
        border-radius: 8px;
        @media (max-width: 1239px) {
            width: 287px;
        }
        @media (max-width: 959px) {
            width: 300px;
            padding: 20px;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }

        h3 {
            margin-bottom: 25px;
            @media (max-width: 1239px) {
                margin-bottom: 14px;
            }
        }
    }
    &__itemImg {
        width: 320px;
        height: 320px;
        margin-bottom: 27px;
        @media (max-width: 1239px) {
            width: 227px;
            height: 227px;
            margin-bottom: 14px;
        }
        @media (max-width: 959px) {
            width: 260px;
            height: 260px;
            margin-bottom: 16px;
        }
    }
    &__pricesRow {
        display: flex;
        border-top: 1px solid rgba(16, 32, 39, 0.15);
        padding-top: 16px;

        .price {
            width: 160px;
            @media (max-width: 1239px) {
                width: 114px;
            }
            label {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0;
                text-align: left;
                margin-bottom: 5px;
                opacity: 0.5;
            }
        }
    }
    &__quantityRow {
        display: flex;
        justify-content: space-between;
        margin-top: 21px;
        margin-bottom: 6px;
        @media (max-width: 1239px) {
            flex-direction: column;
            margin-top: 11px;
        }
        @media (max-width: 959px) {
            flex-direction: row;
            width: 260px;

            .blueButton {
                padding: 16px 25px;
                height: 50px;
                margin-top: 2px;
            }
        }
    }
    &__quantityHolder {
        label {
            display: block;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
            margin-bottom: 5px;
            opacity: 0.5;
        }
    }
    &__quantity {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 154px;
        border-bottom: 1px solid rgba(16, 32, 39, 0.15);
        padding-bottom: 10px;
        @media (max-width: 1239px) {
            width: 227px;
            margin-bottom: 20px;
        }
        @media (max-width: 959px) {
            width: 124px;
            padding-bottom: 4px;
        }
        input {
            border: 0;
            height: 25px;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0;
            width: 120px;
            padding: 0;
            background: #f6f8f8;
            @media (max-width: 959px) {
                width: 86px;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0;
                text-align: center;
                height: 20px;
            }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        .minus {
            margin-left: -5px;
            cursor: pointer;
            @media (max-width: 1239px) {
                margin-right: -5px;
            }
        }
        input[type="number"] {
            -moz-appearance: textfield;
        }
        .plus {
            margin-left: -5px;
            cursor: pointer;
        }
    }

    &__step2 {
        .container {
            position: relative;
        }
    }
    &__grayPlaceholder {
        position: absolute;
        height: 100%;
        right: -200px;
        top: 0;
        background-color: #f6f8f8;
        width: 755px;
        z-index: -1;
        @media (max-width: 1239px) {
            width: 490px;
            right: -50px;
        }
        @media (max-width: 959px) {
            top: 577px;
            height: calc(100% - 577px);
            width: 100vw;
            right: calc(((100vw - 300px) / 2) * (-1));
        }
    }
    &__infoRow {
        display: flex;
        @media (max-width: 959px) {
            flex-direction: column;
        }
    }
    &__infoBlock {
        width: 585px;
        padding-top: 52px;
        padding-bottom: 200px;
        @media (max-width: 1239px) {
            padding-top: 32px;
            padding-bottom: 77px;
        }
        @media (max-width: 959px) {
            padding-bottom: 0;
            width: 300px;
        }
        h2 {
            margin-bottom: 31px;
            @media (max-width: 959px) {
                margin-bottom: 14px;
            }
        }
        .animatedInput {
            margin-bottom: 20px;
            @media (max-width: 1239px) {
                margin-bottom: 10px;
            }

            &:nth-child(6) {
                margin-bottom: 0;
            }
        }
        input {
            width: 585px;
            @media (max-width: 1239px) {
                width: 420px;
            }
            @media (max-width: 959px) {
                width: 300px;
            }
        }
        .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 40px;
            @media (max-width: 1239px) {
                flex-direction: column;
                align-items: flex-start;
                margin-top: 30px;
            }
            @media (max-width: 959px) {
                margin-top: 20px;
                .blueButton {
                    width: 300px;
                }
            }
            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0;
                text-align: left;
                color: #10202780;

                a {
                    color: #102027;
                }
                @media (max-width: 1239px) {
                    margin-top: 14px;
                }
            }
        }
    }
    &__infoCart {
        margin-left: 60px;
        padding-left: 60px;
        padding-top: 52px;
        @media (max-width: 1239px) {
            padding-top: 32px;
            padding-left: 30px;
            margin-left: 40px;

            h2 {
                margin-bottom: 30px;
            }
        }
        @media (max-width: 959px) {
            margin-left: 0;
            padding-left: 0;
            padding-top: 0;
            margin-top: 90px;
        }
        & > h3.thin {
            font-weight: 400;
            margin-top: 35px;
            margin-bottom: 20px;
            @media (max-width: 959px) {
                margin-bottom: 10px;
                font-size: 16px;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0;
                text-align: left;
            }
        }
        & > .row {
            display: flex;
            margin-top: 15px;
            margin-bottom: 25px;

            @media (max-width: 959px) {
                margin-top: 10px;
                margin-bottom: 45px;
            }
            .bigPrice {
                &:first-child {
                    width: 218px;
                    @media (max-width: 959px) {
                        width: 150px;
                    }
                }
                &:last-child {
                    width: 277px;
                    @media (max-width: 1239px) {
                        width: 192px;
                    }
                    @media (max-width: 959px) {
                        width: 150px;
                    }
                }
                label {
                    display: block;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0;
                    text-align: left;
                    margin-bottom: 3px;
                    opacity: 0.5;
                    @media (max-width: 959px) {
                        margin-bottom: 5px;
                    }
                }
                span {
                    font-size: 40px;
                    font-weight: 400;
                    line-height: 52px;
                    letter-spacing: 0;
                    text-align: left;
                    @media (max-width: 959px) {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        letter-spacing: 0;
                        text-align: left;
                    }
                }
            }
        }
    }
    &__infoCartCard {
        margin-top: 30px;
        width: 495px;
        padding-bottom: 30px;
        display: flex;
        border-bottom: 1px solid rgba(16, 32, 39, 0.1);
        img {
            width: 100px;
            height: 100px;
            margin-right: 30px;
            background-color: black;
            border-radius: 10px;
        }
        @media (max-width: 1239px) {
            margin-top: 20px;
            width: 410px;
            padding-bottom: 20px;

            img {
                width: 80px;
                height: 80px;
                margin-right: 20px;
            }
        }
        @media (max-width: 959px) {
            width: 300px;
            padding-bottom: 15px;
            img {
                width: 60px;
                height: 60px;
            }
        }
        .rowTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 365px;
            margin-top: 7px;
            margin-bottom: 14px;
            @media (max-width: 1239px) {
                margin-top: 1px;
                width: 310px;
                margin-bottom: 10px;
            }
            @media (max-width: 959px) {
                flex-direction: column;
                align-items: flex-start;
            }

            h3.thin {
                font-weight: 400;
                @media (max-width: 959px) {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0;
                    text-align: right;
                    margin-top: 6px;
                }
            }
        }
        .row {
            display: flex;

            .price {
                width: 112px;
                label {
                    display: block;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0;
                    text-align: left;
                    margin-bottom: 5px;
                    opacity: 0.5;
                    @media (max-width: 1239px) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &__step3 {
        padding-top: 60px;
        padding-bottom: 150px;
        @media (max-width: 1239px) {
            padding-top: 40px;
            padding-bottom: 100px;
        }
        @media (max-width: 959px) {
            padding-bottom: 80px;
        }
        .container {
            display: flex;
            justify-content: center;
        }
    }
    &__phoneConfirmation,
    &__confirmationBlock {
        width: 705px;
        box-sizing: border-box;
        border-radius: 8px;
        background: #f6f8f8;
        padding: 52px 60px 60px 60px;
        @media (max-width: 1239px) {
            width: 500px;
            padding: 42px 30px 41px 30px;
        }
        @media (max-width: 959px) {
            width: 300px;
            padding: 45px 20px 50px 20px;
        }
        img.mob {
            display: none;

            @media (max-width: 959px) {
                display: block;
                margin-left: 90px;
                margin-bottom: 30px;
            }
        }
        h2 {
            margin-bottom: 25px;
            @media (max-width: 1239px) {
                margin-bottom: 15px;
            }
            @media (max-width: 959px) {
                margin-bottom: 8px;
            }
        }
        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
            margin-bottom: 34px;
            @media (max-width: 1239px) {
                margin-bottom: 24px;
            }
            @media (max-width: 959px) {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0;
                text-align: left;
            }
        }
        input {
            width: 585px;
            @media (max-width: 1239px) {
                width: 440px;
            }
            @media (max-width: 959px) {
                width: 260px;
            }
        }
        p.margin {
            margin-bottom: 71px;
            @media (max-width: 1239px) {
                margin-bottom: 29px;
                br {
                    display: none;
                }
            }
        }
        .row {
            display: flex;

            align-items: center;
            &:first-child {
                margin-bottom: 25px;
                @media (max-width: 1239px) {
                    margin-bottom: 15px;
                }
                @media (max-width: 959px) {
                    margin-bottom: 8px;
                }
            }
            &:last-child {
                @media (max-width: 1239px) {
                    flex-direction: column;

                    button {
                        width: 440px;
                        margin-right: 0;

                        &:first-child {
                            margin-bottom: 10px;
                        }
                    }
                }
                @media (max-width: 959px) {
                    button {
                        width: 260px;
                    }
                }
            }
            img {
                margin-left: 20px;
                @media (max-width: 959px) {
                    display: none;
                }
            }
            h2 {
                margin-bottom: 0;
            }
            .blueButton {
                margin-right: 20px;
            }
        }
    }
    &__phoneActions {
        display: flex;
        margin-top: 36px;
        flex-wrap: wrap;
        row-gap: 17px;
        @media (max-width: 959px) {
            row-gap: 13px;
            margin-top: 27px;
        }
        button {
            margin-right: 40px;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0;
            text-align: left;
            width: max-content;
            position: relative;
            @media (max-width: 959px) {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0;
                text-align: left;
            }
        }
        button::before {
            position: absolute;
            bottom: -4px;
            content: "";
            height: 1px;
            width: 0;
            background-color: #01579b;
            transition-duration: 300ms;
            opacity: 0;
        }
        button:hover:before {
            width: 100%;
            opacity: 1;
        }
    }

    &__empty-block {
        padding: 150px 0;

        .error-message {
            font-size: 20px;
            font-weight: 400;
            color: #ff3d3d;
            text-align: center;
        }
    }

    &__steps-wrapper {
        position: relative;
    }

    &__steps-darken {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(196, 196, 196, 0.75);
        z-index: 10;
    }
}

.checkout-form {
    &__card-element {
        border: 1px solid #10202733;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 20px;
    }

    &__error {
        margin-bottom: 20px;
        color: #ff3d3d;
        text-align: center;
    }

    &__button {
        display: flex;
        justify-content: center;
    }
}
