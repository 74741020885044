.callUs{
  padding-bottom: 150px;
  @media (max-width: 1239px) {
    padding-bottom: 100px;
  }
  @media (max-width: 959px) {
    background: #F6F8F8;
    padding-top: 45px;
    padding-bottom: 45px;
  }

  &__contacts{
    margin-right: 154px;
    @media (max-width: 1239px) {
      margin-right: 9px;
    }
    @media (max-width: 959px) {
      margin-right: 0;
    }
      span{
      font-size: 30px;
      font-weight: 400;
      line-height: 39px;
      letter-spacing: 0;
      text-align: left;
      width: 431px;
      margin-bottom: 27px;
      @media (max-width: 1239px) {
        margin-bottom: 17px;
      }
      @media (max-width: 959px) {
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0;
        text-align: left;
        width: 300px;
        margin-bottom: 20px;
        margin-top: 40px;
      }
    }
  }
  &__contact{
    &:nth-child(2){
      margin-bottom: 29px;
      @media (max-width: 1239px) {
        margin-bottom: 21px;
      }
    }
    label{
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0;
      text-align: left;
      margin-bottom: 9px;
      opacity: 0.6;
      display: block;
      @media (max-width: 1239px) {
        margin-bottom: 4px;
      }
    }
    p{
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      @media (max-width: 959px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
      }
    }
  }
  &__links{
    display: flex;
    margin-top: 44px;
    @media (max-width: 1239px) {
      margin-top: 24px;
    }
    button{
      margin-right: 10px;
    }
  }
  .container{
    max-width: 1320px;
    background: #F6F8F8;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 52px 60px 60px;
    box-sizing: border-box;
    @media (max-width: 1239px) {
      max-width: 960px;
      padding: 42px 30px 45px 30px;
    }
    @media (max-width: 959px) {
      max-width: 300px;
      padding: 0;
      flex-direction: column;
    }
  }

  h2{
    width: 471px;

    @media (max-width: 1239px) {
      width: 438px;
    }
    @media (max-width: 959px) {
      width: 300px;
      margin-bottom: 18px;
    }
  }

  &__content{
    @media (max-width: 1239px) {
      margin-top: 6px;
    }
    h2{
      margin-bottom: 24px;
      @media (max-width: 1239px) {
        margin-bottom: 15px;
      }
    }
    & > p{
      width: 585px;
      margin-bottom: 33px;
      @media (max-width: 1239px) {
        width: 440px;
        margin-bottom: 24px;
      }
      @media (max-width: 959px) {
        width: 300px;
      }
    }

    .animatedInput input{
      width: 503px;
      @media (max-width: 1239px) {
        width: 440px;
      }
      @media (max-width: 959px) {
        width: 300px;
      }
    }
    &-2{
      @media (max-width: 1239px) {
        margin-top: 0;
      }
      & > p{
        width: 431px;
        @media (max-width: 1239px) {
          width: 392px;
        }
        @media (max-width: 959px) {
          width: 300px;
        }
      }
      .animatedInput input{
        width: 491px;
        @media (max-width: 1239px) {
          width: 392px;
        }
        @media (max-width: 959px) {
          width: 300px;
        }
      }
    }

    &-sentTitle{
      h2 {
        display: flex;
        align-items: center;
        column-gap: 20px;
        margin-bottom: 24px;

        @media (max-width: 1239px) {
          margin-bottom: 15px;
        }

        img {
          width: 40px;
          height: 40px;

          @media (max-width: 959px) {
            width: 32px;
            height: 32px;
          }
        }
      }
    }

  }
  &__submitRow{
    display: flex;
    align-items: center;
    margin-top: 41px;

    @media (max-width: 1239px) {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 30px;
    }

    button{
      margin-right: 30px;
      @media (max-width: 1239px) {
        margin-bottom: 14px;
      }
      @media (max-width: 959px) {
        width: 300px;
        margin-right: 0;
        margin-bottom: 26px;
      }
    }
    p{
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0;
      text-align: left;
      color: #10202780;

      a{
        color: #102027;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
        @media (max-width: 959px) {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0;
          text-align: left;
        }
      }
      @media (max-width: 959px) {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0;
        text-align: left;
      }
    }
  }

  &__spinner {
    display: flex;
    align-items: center;
    height: 150px;
  }

  &__sentTitle {
    display: flex;
    align-items: center;
    column-gap: 20px;

    img {
      width: 40px;
      height: 40px;

      @media (max-width: 960px) {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__error {
    margin: 15px 0 0;
    color: #d60000;
    text-align: center;
  }
}