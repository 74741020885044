.servicesPage{
  
  @media (max-width: 1239px) {
    &__services{
      .services{
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
      }
    }
  }
  @media (max-width: 959px) {
    .services__dots{
      display: none!important;
    }
    .services__card{
      min-width: 300px!important;
    }
  }
  &__winnings{
    margin-bottom: 53px;
    @media (max-width: 1239px) {
      margin-bottom: 37px;
    }
    @media (max-width: 959px) {
      margin-bottom: 25px;
    }
  }
  &__winningsRow{
    display: flex;
    justify-content: space-between;
    @media (max-width: 959px) {
      flex-direction: column;
    }
    p{
      width: 585px;
      @media (max-width: 1239px) {
        width: 440px;
      }
      @media (max-width: 959px) {
        width: 300px;
        margin-top: 18px;
      }
    }
  }
  &__services{
    margin-bottom: 141px;
    @media (max-width: 1239px) {
      margin-bottom: 92px;
    }
    @media (max-width: 959px) {
      margin-bottom: 75px;
    }
  }
  &__content{
    padding-top: 56px;
    @media (max-width: 1239px) {
      padding-top: 36px;
    }
    @media (max-width: 959px) {
      padding-top: 24px;
    }

    h2{
      margin-bottom: 51px;
      @media (max-width: 1239px) {
        margin-bottom: 31px;
      }
    }
    .container{
      label{
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
        opacity: 0.5;
        margin-bottom: 47px;
        display: block;
        @media (max-width: 1239px) {
          margin-bottom: 27px;
        }
        @media (max-width: 959px) {
          margin-bottom: 20px;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0;
          text-align: left;

        }
      }


    }
  }
}