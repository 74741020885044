.infoTable{
  .container{
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1239px) {
      justify-content: space-between;
    }
  }
  &__info{

    margin-bottom: 64px;
    @media (max-width: 1239px) {
      margin-bottom: 55px;
    }
    @media (max-width: 959px) {
      margin-bottom: 25px;
    }
    img:first-child{
      margin-bottom: 35px;
      @media (max-width: 1239px) {
        margin-bottom: 25px;
      }

    }
    h3{
      margin-bottom: 28px;
      @media (max-width: 1239px) {
        margin-bottom: 18px;
      }
      @media (max-width: 959px) {
        margin-bottom: 9px;
      }

    }
    p{
      margin-bottom: 28px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      @media (max-width: 1239px) {
        margin-bottom: 18px;
      }
      @media (max-width: 959px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 8px;
        width: 300px!important;
        br{
          display: none;
        }
      }
    }
  }
  &__info-1{
    margin-right: 161px;
    @media (max-width: 1239px) {
      margin-right: 0;
    }
    img:first-child{
      margin-left: -2px;
      @media (max-width: 959px) {
        margin-bottom: 16px;
      }
    }
    h3{
      width: 296px;
      @media (max-width: 1239px) {
        width: 259px;
      }
    }
    p{
      width: 280px;
      @media (max-width: 1239px) {
        width: 259px;
      }
    }
  }
  &__info-2{
    margin-right: 163px;
    @media (max-width: 1239px) {
      margin-right: 0;
    }
    img:first-child{
      margin-left: -8px;
      @media (max-width: 959px) {
        margin-bottom: 12px;
      }
    }
    h3{
      width: 276px;
      @media (max-width: 1239px) {
        width: 262px;
      }
    }
    p{
      width: 290px;
      @media (max-width: 1239px) {
        width: 262px;
      }
    }
  }
  &__info-3{
    img:first-child{
      margin-left: -5px;
      @media (max-width: 959px) {
        margin-bottom: 6px;
      }
    }
    h3{
      width: 278px;
      @media (max-width: 1239px) {
        width: 286px;
      }

    }
    p{
      width: 278px;
      @media (max-width: 1239px) {
        width: 286px;
      }

    }
  }
  &__info-4{
    margin-right: 181px;
    @media (max-width: 1239px) {
      margin-right: 0;
    }
    img:first-child{
      margin-left: -4px;
      @media (max-width: 959px) {
        margin-bottom: 13px;
      }
    }
    h3{
      width: 278px;
      @media (max-width: 1239px) {
        width: 261px;
      }
      @media (max-width: 959px) {
        br{
          display: none;
        }
      }
    }
    p{
      width: 248px;
      @media (max-width: 1239px) {
        width: 261px;


      }
    }
  }
  &__info-5{
    margin-right: 164px;
    @media (max-width: 1239px) {
      margin-right: 0;
    }
    img:first-child{
      margin-left: -6px;
      @media (max-width: 959px) {
        margin-bottom: 8px;
      }
    }
    h3{
      width: 277px;
      @media (max-width: 1239px) {
        width: 262px;
      }
    }
    p{
      width: 290px;
      @media (max-width: 1239px) {
        width: 262px;

        br{
          display: none;
        }
      }
    }
  }
  &__info-6{
    span{
      display: inline;
    }
    img:first-child{
      margin-left: -4px;
      @media (max-width: 959px) {
        margin-bottom: 12px;
      }
    }
    h3{
      width: 277px;
      @media (max-width: 1239px) {
        width: 287px;
      }
    }
    p{
      width: 277px;
      @media (max-width: 1239px) {
        width: 287px;

        span{
          display: none;
        }
      }
    }
  }
}