.pictureWithText{
  padding-top: 156px;
  padding-bottom: 157px;


  @media (max-width: 1239px) {
    padding-top: 100px;
    padding-bottom: 92px;
  }
  @media (max-width: 959px) {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  &-2{
    padding-top: 100px;
    padding-bottom: 0;

    @media (max-width: 1239px) {
      padding-top: 100px;
    }
  }
  &__rowsHolder{
    margin-top: 48px;
    @media(max-width: 1239px){
      margin-top: 28px;
    }
  }
  &__row{
    display: flex;
    align-items: center;
    margin-bottom: 23px;

    &:last-child{
      margin-bottom: 0;
    }

    .line{
      width: 30px;
      height: 1px;
      background-color: #102027;
      margin-right: 20px;
      @media(max-width: 1239px){
        margin-top: -25px;
      }
      @media (max-width: 959px) {
        margin-right: 10px;
      }
    }
    h3{
      width: 400px;
      span{
        display: inline;
      }
      @media(max-width: 1239px){
        width: 336px;

        span{
          display: none;
        }
      }
      @media (max-width: 959px) {
        width: 260px;
      }
    }
  }
  .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 959px) {
      flex-direction: column;
    }
  }
  &__img{
    width: 584px;
    @media (max-width: 1239px) {
      width: 440px;
    }
    @media (max-width: 959px) {
      width: 100vw;
      height: 320px;
      object-fit: cover;
      margin-top: 35px;
    }
  }
  &__text{
    h2{
      margin-bottom: 44px;
      @media (max-width: 1239px) {
        margin-bottom: 25px;
      }
      @media (max-width: 959px) {
        width: 300px;
      }
    }
    p{
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0;
      text-align: left;
      width: 485px;
      @media (max-width: 1239px) {
        width: 410px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;

        br{
          display: none;
        }

      }
      @media (max-width: 959px) {
        width: 300px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
      }
    }

    &-2{
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0;
        text-align: left;
        width: 538px;
        @media(max-width: 1239px) {
          width: 410px;
        }
        @media (max-width: 959px) {
          width: 300px;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
          br{
            display: none;
          }
        }
      }
    }
  }
}