.aboutBlock{
  padding-top: 141px;

  &__buttonsRow{
    display: flex;
  }
  &__controlsRow{
    display:none;



  }
  @media(max-width: 1239px){
    padding-top: 95px;

    &__buttonsRow{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;

      @media (max-width: 959px) {
        margin-top: 30px;
      }
      .blueButton{
        margin-top: 0!important;
        margin-left: 0!important;
        @media (max-width: 959px) {
          width: 300px;
        }
      }
    }

    &__controlsRow{
      display: flex;

      button:first-child{
        margin-right: 10px;
      }
      @media (max-width: 959px) {
        display: none;
      }
    }
  }

  & > .container{
    padding-bottom: 82px;
    @media(max-width: 1239px){
      padding-bottom: 39px;
    }
  }
  h3.thin{
    opacity: 0.5;
    font-weight: 400;
    margin-bottom: 16px;
  }
  &-h2{
    width: 957px;
    margin-bottom: 31px;

    @media (max-width: 1239px) {
      width: 810px;
    }
    @media (max-width: 959px) {
      width: 300px;
    }
    span{
      color: #01579B;
      display: inline;
    }
  }
  .blueButton{
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
  }
  &__partnersRow{
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 96px;
    img{
      cursor: pointer;
    }
    img:nth-child(3){
      height: 60px;
    }
    img:nth-child(1){
      height: 80px;
    }

    @media (max-width: 1239px) {
      margin-bottom: 57px;
      img{
        height: 25px;
      }
    }
    @media (max-width: 959px) {
      flex-direction: column;
      margin-bottom: 0;

      img{
        padding-top: 28px;
        padding-bottom: 28px;
        background-color: #F6F8F8;
        border-radius: 8px;
        margin-bottom: 10px;
      }
      img:nth-child(1){
        padding-left: calc(50% - 67px);
        padding-right: calc(50% - 67px);
      }
      img:nth-child(2){
        padding: 55px calc(50% - 64px);
      }
      img:nth-child(3){
        padding-left: calc(50% - 83px);
        padding-right: calc(50% - 83px);
        padding-top: 43px;
        padding-bottom: 43px;
        height: 50px;
      }
    }
  }
  .aboutBlock__mapRow{
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;

    @media (max-width: 959px) {
      flex-direction: column;
      margin-bottom: 60px;
    }
    .text{
      margin-top: 124px;
      @media (max-width: 959px) {
        margin-top: 55px;
      }
      p{
        width: 399px;
        margin-bottom: 27px;
        @media (max-width: 959px) {
          width: 300px;
        }
      }
    }

  }
  &__map{
    border-radius: 8px;
    @media (max-width: 1239px) {
      background-color: #F6F8F8;
      object-fit: contain;
      width: 440px;
      height: 500px;
    }
    @media (max-width: 959px) {
      width: 300px;
      height: 250px;
      margin-top: 34px;
    }
  }
  &__services{
    background-color: #F6F8F8;
    padding-top: 139px;
    padding-bottom: 150px;
    @media(max-width: 1239px){
      padding-top: 92px;
      padding-bottom: 95px;
      overflow: hidden;

    }
    @media (max-width: 959px) {
      padding-bottom: 0;
      padding-top: 20px;
      background-color: transparent;
    }
    .container{
      display: flex;
      flex-direction: column;

    }
  }
  &__servicesRow{
    display: flex;
    justify-content: space-between;
    margin-bottom: 84px;
    @media(max-width: 1239px){
      margin-bottom: 34px;
    }
    @media(max-width: 959px){
      flex-direction: column;
    }
    &-p{
      margin-top: 10px;
      width: 585px;

      @media(max-width: 1239px){
        width: 440px;

        br{
          display: none;
        }
      }
      @media(max-width: 959px){
        width: 300px;
        margin-top: 28px;
      }
    }
  }
}