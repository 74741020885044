.documents{
  margin-bottom: 150px;
  @media (max-width: 1239px) {
    margin-bottom: 100px;
  }
  @media (max-width: 959px) {
    margin-bottom: 0;
    background-color: #F6F8F8;
    padding-top: 45px;
    padding-bottom: 19px;
  }
  .container{
    max-width: 1320px;
    padding: 52px 58px 54px 60px;
    box-sizing: border-box;
    background-color: #F6F8F8;
    border-radius: 8px;
    @media (max-width: 1239px) {
      max-width: 960px;
      padding: 42px 30px 89px 30px;
    }
    @media (max-width: 959px) {
      max-width: 300px;
      padding: 0;

    }
    h2{
      margin-bottom: 52px;
      @media (max-width: 959px) {
        margin-bottom:34px;
      }
    }
  }
  &__row{
    display: flex;
    justify-content: space-between;
    @media (max-width: 959px) {
      flex-direction: column;
    }
  }
  &__doc{
    border-top: 1px solid #10202726;
    display: flex;
    flex-direction: column;
    width: 374px;
    height: 218px;
    padding-top: 35px;
    box-sizing: border-box;
    @media (max-width: 1239px) {
        width: 273px;
        height: 214px;
    }
    @media (max-width: 959px) {
      width: 300px;
      height: 153px;
      padding-top: 20px;
    }

    @media (max-width: 959px) {
      h3{
        br{
          display: none;
        }
      }
    }
    &-img{
      margin-bottom: 29px;
      width: 33px;
      @media (max-width: 959px) {
        margin-bottom: 16px;
      }
    }
    .arrowButton{
      margin-top: auto;
      @media (max-width: 959px) {
        margin-top: 8px;
      }
    }
  }
}