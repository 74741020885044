.reviews{
  padding-bottom: 142px;
  @media (max-width: 1239px) {
    padding-bottom: 92px;
  }
  @media (max-width: 959px) {
    padding-bottom: 74px;
  }
  &__paging{
    display: flex;
    @media (max-width: 959px) {
      .dots{
        display: flex;
        margin-right: auto;
        margin-left: auto;
      }
      .dot{
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #102027;
        opacity: 0.3;
        margin-right: 20px;
        &:last-child{
          margin-right: 0;
        }
      }
      .dot.active{
        opacity: 1;
      }
    }

  }
  h2{
    margin-bottom: 47px;
    @media (max-width: 1239px) {
      margin-bottom: 24px;
    }
  }

  &__content{
    height: 240px;
    margin-bottom: 26px;
    @media (max-width: 959px) {
      height: 462px;
      margin-bottom: 22px;
    }
    p{
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0;
      text-align: left;
      width: 893px;
      transition: opacity 0.15s;
      opacity: 0;
      &.shown{
        opacity: 1;
      }
      @media (max-width: 959px) {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;
        width: 300px;

        br{
          display: none;
        }
      }
    }
  }

  label{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    text-align: left;
    color: #10202780;
    display: block;
    width: 695px;
    margin-bottom: 36px;
    a{
      color: #102027
    }
    @media (max-width: 959px) {
      width: 300px;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0;
      text-align: left;
      margin-bottom: 24px;
    }
  }

  &__counter{
    @media (max-width: 959px) {
      display: none;
    }
    p{
      display: inline;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;


      span{
        display: inline;
        font-size: 40px;
        font-weight: 400;
        line-height: 48px;
        letter-spacing: 0;
        text-align: left;
      }
    }
  }

  &__controls{
    display: flex;
    margin-top: 11px;

    button:first-child{
      margin-right: 10px;
    }
  }
}