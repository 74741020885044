.otherBanner{
  padding-top: 90px;
  @media (max-width: 1239px) {
    padding-top: 50px;
    padding-bottom: 0;
  }
  @media (max-width: 959px) {
    .blueButton{
      width: 300px;
    }
  }
  h1{
    margin-bottom: 23px;
    @media (max-width: 1239px) {
      margin-bottom: 23px;
      width: 880px;

      br{
        display: none;
      }
    }
    @media (max-width: 959px) {
     margin-bottom: 7px;
      width: auto;
    }
  }
  p{
    margin-bottom: 34px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    width: 585px;
    @media (max-width: 1239px) {
      width: 440px;
      br{
        display: none;
      }
    }
    @media (max-width: 959px) {
      width: 300px;
      margin-bottom: 25px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0;
      text-align: left;

    }
  }
}