.calculator{
  padding-top: 150px;
  padding-bottom: 142px;


  @media (max-width: 1239px) {
    padding-top: 100px;
    padding-bottom: 92px;
  }
  @media (max-width: 959px) {
    padding-top: 80px;
    padding-bottom: 75px;
  }
  .container{
    max-width: 1320px;
    @media (max-width: 1239px) {
      max-width: 960px;
    }
    @media (max-width: 1239px) {

      background-color: #F6F8F8;
    }
  }

  &__content{
    background-color: #F6F8F8;
    border-radius: 8px;
    padding: 52px 60px 60px;
    @media (max-width: 1239px) {
      padding: 42px 30px 50px 30px;
    }

    @media (max-width: 959px) {
      padding: 45px 0px 50px 0px;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    h2{
      margin-bottom: 35px;
      @media (max-width: 959px) {
        margin-bottom: 28px;
      }
    }
  }
  &__row{
    display: flex;
    justify-content: space-between;
    & > p{
      width: 555px;
      @media (max-width: 1239px) {
        width: 420px;
      }
      @media (max-width: 959px) {
        width: 300px;
        margin-bottom: 25px;
      }
    }
    @media (max-width: 959px) {
      flex-direction: column;
    }
  }
  &__fieldsRow{
    display: flex;
    width: 586px;
    margin-top: 7px;
    @media (max-width: 1239px) {
      width: 440px;
    }
    @media (max-width: 959px) {
      width: 300px;
      flex-direction: column;
    }

    .animatedInput, .animatedInput input{
      width: 278px;
      @media (max-width: 1239px) {
        width: 210px;
      }
      @media (max-width: 959px) {
        width: 300px;
      }
    }
    .animatedInput:first-child{
      margin-right: 30px;
      @media (max-width: 1239px) {
        margin-right: 20px;
      }
      @media (max-width: 959px) {
        margin-right: 0;
        margin-bottom: 20px;
      }

    }
  }
  &__priceRow{
    margin-top: 33px;
    display: flex;
    align-items: center;

    label{
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      opacity: 0.6;
      margin-right: 5px;
    }
    img{
      cursor: pointer;
    }
    @media (max-width: 959px) {
      margin-top: 18px;
    }
  }
  &__block{
    span{
      font-size: 40px;
      font-weight: 400;
      line-height: 52px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 29px;
      @media (max-width: 1239px){
        margin-bottom: 19px;
      }
      @media (max-width: 959px) {
        font-size: 25px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0;
        text-align: left;

      }
    }
  }
  &__buttonsRow{
    button:first-child{
      margin-right: 30px;
    }
    @media (max-width: 1239px){
      display: flex;
      flex-direction: column;
      width: 440px;
      button:first-child{
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    @media (max-width: 959px) {
      width: 300px;
    }
  }
}