.storeMoneyPage{
  &__info{
    margin-top: 142px;

    h2{
      margin-bottom: 71px;
      @media (max-width: 959px) {
        margin-bottom: 34px;
      }
    }
    @media (max-width: 959px) {
      margin-top: 75px;
      margin-bottom: 73px;

    }
  }
  
}