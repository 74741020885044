.delivery {
  &-type {
    margin-top: 15px;
    padding: 5px;
    max-width: max-content;
    column-gap: 10px;
    cursor: pointer;
  }

  &-countries {
    display: flex;
    column-gap: 15px;
    margin-left: 20px;
    margin-top: 10px;
  }
}