.cart {
    position: absolute;
    top: 35px;
    right: 0;
    z-index: 1000;
    display: flex;
    box-sizing: border-box;
    min-height: 670px;

    @media (max-width: 960px) {
        display: none;
    }

    &__wrapper {
        position: relative;
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @media (max-width: 959px) {
            position: absolute;
            right: 10px;
            top: 15px;
            filter: invert(1);
            opacity: 0.3;
            margin: 0;
        }
    }

    &__inner {
        //width: 552px;
        width: 380px;
        background-color: #f6f8f8;
        //padding: 52px 62px;
        padding: 30px;
        box-sizing: border-box;

        //@media (max-width: 1239px) {
        //    //width: 500px;
        //    //max-height: 695px;
        //    padding: 42px 50px 39px 30px;
        //}
        //@media (max-width: 959px) {
        //    width: 320px;
        //    padding: 45px 10px;
        //    margin-left: auto;
        //}

        &-flex {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            &-content {
                flex-grow: 1;
                padding-bottom: 20px;

                & > h2 {
                    margin-bottom: 46px;
                    @media (max-width: 1239px) {
                        margin-bottom: 26px;
                    }
                }

                & > h3.thin {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 26px;
                    letter-spacing: 0;
                    text-align: left;
                    //margin-top: 55px;
                    //padding-top: 20px;

                    @media (max-width: 1239px) {
                        margin-top: 24px;
                    }
                    @media (max-width: 959px) {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 21px;
                        letter-spacing: 0;
                        text-align: left;
                    }
                }
            }
        }

        .rowPrices {
            //display: flex;
            //justify-content: space-between;
            margin-top: 20px;
            @media (max-width: 1239px) {
                margin-top: 15px;
            }
            @media (max-width: 959px) {
                margin-top: 10px;
                width: 221px;
            }

            label {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0;
                text-align: left;
                margin-bottom: 3px;
                display: block;
                opacity: 0.6;
                @media (max-width: 959px) {
                    margin-bottom: 5px;
                }
            }

            span {
                display: block;
                font-size: 40px;
                font-weight: 400;
                line-height: 52px;
                letter-spacing: 0;
                text-align: left;
                @media (max-width: 959px) {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0;
                    text-align: left;
                }
            }

            .totalPrice {
                margin-top: 20px;
            }
        }
    }

    &__item {
        width: 100%;
        //padding-bottom: 30px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(16, 32, 39, 0.1);
        margin-top: 15px;

        &:nth-child(2) {
            margin-top: 0;
        }

        @media (max-width: 1239px) {
            padding-bottom: 0;
        }
        @media (max-width: 959px) {
            margin-top: 16px;
        }

        .itemPriceRow {
            display: flex;
            @media (max-width: 959px) {
                margin-bottom: 15px;
            }
        }

        .rowText {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            margin-bottom: 24px;
            @media (max-height: 1239px) {
                margin-bottom: 10px;
            }

            button {
                height: 25px;
                width: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: -5px;
            }
        }

        .rowControls {
            display: flex;
            @media (max-width: 959px) {
                flex-direction: column;
            }

            .quantity {
                .purchasePage__quantity {
                    width: 158px;
                    margin-top: 6px;

                    input {
                        background-color: white;
                    }

                    @media (max-width: 959px) {
                        margin-bottom: 16px;
                        width: 100px;

                        input {
                            width: 62px;
                        }
                    }
                }
            }

            label {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0;
                text-align: left;
                opacity: 0.5;
                display: block;
            }

            h4 {
                margin-top: 5px;
            }

            .price,
            .lowPrice {
                margin-left: 30px;
                @media (max-width: 959px) {
                    margin-left: 0;
                }
            }

            .price {
                @media (max-width: 959px) {
                    margin-right: 104px;
                }
            }
        }
    }

    &__button-next {
        margin-top: 20px;
        text-align: center;

        .blueButton.disabled {
            opacity: 0.5;
        }
    }
}
