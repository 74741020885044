.otherBanner{
  @media (max-width: 959px) {
    .tooLarge{
      font-size: 27px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0;
      text-align: left;
    }
  }
  .container{
    br.tab{
      display: none;
      @media (max-width: 1239px){
        display: block;
      }
    }
    h1{
      span{
        display: inline;
        color: #01579B;;
      }
    }
    p.small{
      width: 460px;
      margin: 0;
    }
  }
  .container-big{
    max-width: 1600px;


    img{
      margin-top: 100px;
      width: 100%;

      @media (max-width: 1239px) {
        margin-top: 60px;
      }
      @media (max-width: 959px) {
        margin-top: 40px;
        height: 320px;
        object-fit: cover;
        object-position:95% 30%;
      }
    }
  }
}