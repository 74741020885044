.supportPage{
  .faq__h2{
    @media (max-width: 959px) {
      margin-bottom: 24px;
    }
  }
  &__content{
    padding-top: 56px;
    @media (max-width: 1239px) {
      padding-top: 36px;
    }
    @media (max-width: 959px) {
      padding-top: 24px;
    }

    h2{
      margin-bottom: 51px;
      @media (max-width: 1239px) {
        margin-bottom: 31px;
      }


    }

    .container{

      label{
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
        opacity: 0.5;
        margin-bottom: 47px;
        display: block;
        @media (max-width: 1239px) {
          margin-bottom: 27px;
        }
        @media (max-width: 959px) {
          margin-bottom: 20px;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0;
          text-align: left;

        }
      }

    }
  }
}