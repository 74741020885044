.popup{
    padding: 12px 16px;
    box-shadow: 0 6px 10px 2px #0000000F;
    border-radius: 98px;
    background-color: white;
    opacity: 0;
    z-index: 1000;
    position: fixed;
    top: -100px;
    left: 50%;
    transform: translate(-50%, 0);
    transition-duration: 0s;
    border: 1px solid #01579B;
    max-width: 80vw;
}
.popup.showed{
    top: 100px;
    opacity: 1;
    transition-duration: 300ms;
}

.popup__content{
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup p{
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    color: #0F213FCC;
    margin-left: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}