.buyMetalsPage{

  &__info{
    padding-bottom: 72px;
    h2{
      margin-bottom: 71px;
    }
  }
  &__pricesBlock{
    padding-top: 150px;
    padding-bottom: 142px;
    @media (max-width: 959px) {
      padding-top: 45px;
      padding-bottom: 50px;
      background: #F6F8F8;
      margin-top: 80px;
      margin-bottom: 75px;
    }
    h2{
      margin-bottom: 36px;
      @media (max-width: 959px) {
        margin-bottom: 26px;
      }

    }
    .container{
      box-sizing: border-box;
      max-width: 1320px;
      padding: 49px 60px 60px;
      background: #F6F8F8;
      border-radius: 8px;
      @media (max-width: 1239px) {
        max-width: 960px;
        padding: 42px 30px 80px 30px;
      }
      @media (max-width: 959px) {
        max-width: 300px;
        padding: 0;
      }

    }
    .metalPrices{
      margin-top: 3px;
      @media (max-width: 1239px) {
        margin-top: 1px;
      }
    }
  }
  &__pricesRow{
    display: flex;
    justify-content: space-between;
    @media (max-width: 959px) {
      flex-direction: column;
    }
  }
  &__pricesText{
    p{
      width: 585px;
      margin-bottom: 33px;
      @media (max-width: 1239px) {
        width: 440px;
        margin-bottom: 24px;
      }
      @media (max-width: 959px) {
        width: 300px;
        margin-top: 34px;
        margin-bottom: 15px;

        button{
          width: 300px;
        }
      }
    }
    @media (max-width: 959px) {
      button{
        width: 300px;
      }
    }
  }
}