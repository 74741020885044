.storagePage{
  .mob{
    display: none;
  }
  .safety{
    margin-top: 92px;
  }
  @media (max-width: 959px) {
    .mob{
      display: block;
    }
    img.mob{
      height: 40px;
      margin-top: 10px;
      margin-bottom: 16px;
    }
    label.mob{
      width: 300px;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0;
      text-align: left;

    }

    .safety__text{
      img{
        display: none;
      }
      label{
        display: none;
      }
    }
    .otherBanner {
      padding-top: 23px;
      h1{
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 18px;
      }
      p.small{
        width: 300px;
      }
    }
  }

}