.contactsPage{
  &__map{
    height: 800px;
    background-color: #102027;
    @media (max-width: 1239px) {
        height: 500px;
    }
    @media (max-width: 959px) {
      height: 320px;
      width: 100%;
    }

    .container{
      max-width: 1600px;
      @media (max-width: 1239px) {
        max-width: 1000px;
      }
      @media (max-width: 959px) {
        max-width: 300px;
      }
    }
  }
  &__mapTitle{
    margin-bottom: 54px;
    @media (max-width: 1239px) {
      margin-bottom: 34px;
    }
    @media (max-width: 959px) {
      margin-top: 75px;
    }
    h2{
      margin-bottom: 46px;
      @media (max-width: 1239px) {
        margin-bottom: 26px;
      }
    }
    h3.thin{
      font-weight: 400;
      margin-bottom: 8px;
      @media (max-width: 959px) {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;
      }
    }
    p{
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      opacity: 0.6;
      @media (max-width: 959px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
      }
    }
  }
  &__content{
    padding-top: 56px;
    @media (max-width: 1239px) {
      padding-top: 36px;
    }
    @media (max-width: 959px) {
      padding-top: 24px;
    }
    .container{
      label{
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
        opacity: 0.5;
        margin-bottom: 56px;
        display: block;
        @media (max-width: 1239px) {
          margin-bottom: 35px;
        }
        @media (max-width: 959px) {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0;
          text-align: left;
          margin-bottom: 25px;
        }
      }
    }
  }
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}