.pictureWithButtons{
  background-color: #F6F8F8;
  position: relative;
  margin-bottom: 142px;
  @media (max-width: 1239px) {
    margin-bottom: 92px;
  }
  @media (max-width: 959px) {
    background-color: transparent;
    margin-bottom: 75px;
    &.alwaysDark{
      background-color: #F6F8F8;
    }
  }
  .container{
    display: flex;
    justify-content: space-between;
    @media (max-width: 959px) {
      flex-direction: column;
    }
  }
  &__buttonsRow{
    button:first-child{
      margin-right: 20px;
      @media (max-width: 1239px) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
    @media (max-width: 1239px) {
      display: flex;
      flex-direction: column;
    }
  }
  &__content{
    display: flex;
    flex-direction: column;
    justify-content: center;


    &.storeMetals{
      height: 600px;
      @media (max-width: 1239px) {
        height: 410px;
      }
      @media (max-width: 959px) {
        height: auto;
        padding-top: 45px;
        padding-bottom: 50px;
      }
      p{
        width: 425px;
        span{
          display: inline;
        }
        @media (max-width: 959px) {
          width: 300px;
          span{
            display: none
          }
        }

      }
      button{
        width: max-content;
        @media (max-width: 959px) {
          width: 300px;
        }
      }
    }
    &.sellMetals{
      height: 622px;
      @media (max-width: 1239px) {
        height: 587px;
      }
      @media (max-width: 959px) {
        height: auto;
      }

      h2{
        width: 482px;
        @media (max-width: 1239px) {
          width: 402px;
        }
        @media (max-width: 959px) {
          width: 300px;
        }
      }
      p{
        width: 482px;
        @media (max-width: 1239px) {
          width: 402px;
        }
        @media (max-width: 959px) {
          width: 300px;

          br{
            display: none;
          }
        }
      }
    }
  }

  h3{
    margin-bottom: 16px;
    font-weight: 400;
    opacity: 0.5;
    @media (max-width: 959px) {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0;
      text-align: left;
      margin-bottom: 10px;
    }
  }
  h2{
    margin-bottom: 35px;
    @media (max-width: 1239px) {
      margin-bottom: 15px;
    }
    @media (max-width: 959px) {
      margin-bottom: 18px;
    }

  }
  p{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 44px;
    @media (max-width: 1239px) {
      margin-bottom: 34px;
    }


  }
  img{
    width: 800px;
    position: absolute;
    right: 0;

    @media (max-width: 1239px) {
      width: 500px;
      height: 410px;
      object-fit: cover;
    }
    &.img2{
      height: 622px;
      @media (max-width: 1239px) {
        height: 587px;
        width: 500px;
      }
      @media (max-width: 959px) {
        width: 100vw;
        height: 320px;
        margin-top: 50px;
      }
    }
    @media (max-width: 959px) {
      position: initial;
      margin-left: calc(((100vw - 300px) / 2) * (-1));
      width: 100vw;
      height: 320px;
    }
  }
}