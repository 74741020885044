.safety{

  padding-bottom: 71px;
  @media (max-width: 1239px) {
    padding-bottom: 40px;
  }
  .container{
    display: flex;
    justify-content: space-between;
    @media (max-width: 959px){
      flex-direction: column;
    }
  }
  &__img{
    margin-top: 217px;
    margin-bottom: 24px;
  }
  &__label{
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    opacity: 0.5;
    width: 360px;
  }
  &__text{
    width: 500px;
    @media (max-width: 1239px){
      width: 410px;
    }
    @media (max-width: 959px){
      width: 300px;
    }
    h2{
      margin-bottom: 44px;
      @media (max-width: 1239px){
        margin-bottom: 24px;
      }
    }
    p{
      margin-bottom: 27px;
      @media (max-width: 1239px){
        width: 410px;

        br:first-child{
          display: none;
        }
      }
      @media (max-width: 959px){
        width: 300px;
        margin-bottom: 18px;
        br:nth-child(4), br:nth-child(5){
          display: none;
        }
      }

    }
    p.noMargin{
      margin-bottom: 0;
    }


  }
  &__table{
    display: flex;
    flex-wrap: wrap;
    width: 585px;
    margin-top: 15px;
    justify-content: space-between;

    @media (max-width: 1239px) {
      width: 440px;
    }
    @media (max-width: 959px){
      width: 300px;
      margin-top: 33px;
    }
  }

  &__tableItem{
    width: 268px;
    padding-top: 35px;
    padding-bottom: 75px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    @media (max-width: 1239px){
      width: 200px;
      padding-top: 25px;
      padding-bottom: 55px;
      &:nth-child(1){
        h4{
          margin-bottom: 42px;
        };
      }
      &:nth-child(2), &:nth-child(3), &:nth-child(4){
        h4{
          margin-bottom: 19px;
        };
      }
    }
    @media (max-width: 959px){
      padding-top: 15px;
      padding-bottom: 25px;
      width: 300px;
    }

    h4{
      margin-bottom: 29px;
      @media (max-width: 959px){
        width: 300px;
        margin-bottom: 9px!important;

        br{
          display: none;
        }
      }
    }
    p{
      width: 268px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      @media (max-width: 1239px){
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
        width: 200px;

        br{
          display: none;

        }
      }
      @media (max-width: 959px){
        width: 300px;
      }
    }
  }
}