.banner{
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #F6F8F8;




  @media (max-width: 1239px) {
    padding-bottom: 80px;
  }
  @media (max-width: 959px) {
    height: 564px;
    box-sizing: border-box;
    overflow: hidden;
    padding-bottom: 0;
  }

  &__slider{
    display: none;
    width: 150px;
    height: 20px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 0;
    right: calc(50% - 75px);
    align-items: center;
    justify-content: center;
    @media(max-width: 959px){
      display: flex;
    }
  }
  &__sliderRow{
    display: flex;
    justify-content: space-between;


    width: 84px;

    .dot{
      width: 6px;
      height: 6px;
      background-color: #102027;
      opacity: 0.3;
      border-radius: 50%;
    }
    .dot.active{
      opacity: 1;
    }
  }
  .container{
    position: relative;
    @media (max-width: 959px) {
      height: 100%;
    }
  }
  &__block{
    display: flex;
    justify-content: space-between;
    opacity: 0;
    transition: opacity 0.15s;
    @media (max-width: 959px) {
        height: 100%;
    }

    &.shown{
      opacity: 1;
    }
    &-solo{
      opacity: 1!important;
    }
    @media (max-width: 959px) {
      flex-direction: column;
    }
  }
  &__textBlock{
    margin-top: 11px;

    @media (max-width: 1239px) {
      margin-top: 0;
    }
    &-solo{
      margin-top: 90px;
      @media (max-width: 1239px) {
        margin-top: 0;
      }
    }
  }
  &__block-1{
    h1{
      margin-bottom: 22px;
      @media (max-width: 1239px) {
        margin-bottom: 12px;
      }
      @media (max-width: 959px) {
        width: 300px;
      }
    }
    p{
      margin-bottom: 34px;
      width: 515px;
      span{
        display: inline;
      }
      @media (max-width: 1239px) {
        margin-bottom: 30px;
        width: 440px;
        span{
          display: none;
        }
      }
      @media (max-width: 959px){
        width: 300px;
      }
    }
    img{
      width: 559px;
      height: 555px;
      @media (max-width: 1239px) {
        width: 400px;
        height: 400px;
      }
      @media (max-width: 959px) {
        width: 300px;
        height: auto;
        margin-top: auto;
        margin-bottom: -146px;
      }
    }
  }
  &__block-2{
    h1{
      margin-bottom: 76px;
      span{
        display: inline;
      }
      @media (max-width: 1239px) {
        margin-bottom: 12px;
        width: 440px;
        span{
          display: none;
        }
      }
      @media (max-width: 959px) {
        width: 300px;
      }
    }
    p{
      margin-bottom: 34px;
      width: 515px;
      @media (max-width: 1239px) {
        margin-bottom: 30px;
        width: 380px;
      }
      @media (max-width: 959px) {
        width: 300px;
      }

    }
    img{
      margin-top: -7px;
      width: 657px;
      height: 562px;
      @media (max-width: 1239px) {
        margin-top: 0;
        width: 450px;
        height: 400px;
      }
      @media (max-width: 959px) {
        width: 300px;
        height: auto;
        margin-top: auto;
        margin-bottom: -146px;
      }
    }

  }
  &__block-3{
    h1{
      margin-bottom: 22px;
      width: 535px;
      span{
        display: inline;
      }
      span.tab{
        display: none;
      }
      @media (max-width: 1239px) {
        margin-bottom: 12px;
        width: 440px;
        br{
          display: none;
        }
        span{
          display: none;
        }
        span.tab{
          display: inline;
        }
      }
      @media (max-width: 959px) {
        width: 300px;
      }
    }
    p{
      margin-bottom: 34px;
      width: 410px;

      @media (max-width: 1239px) {
        margin-bottom: 30px;
        width: 330px;
      }
    }
    img{
      margin-top: -21px;
      width: 557px;
      height: 576px;
      @media (max-width: 1239px) {
        margin-top: -23px;
        width: 410px;
        height: 423px;
      }
      @media (max-width: 959px) {
        width: 300px;
        height: auto;
        margin-top: auto;
        margin-bottom: -146px;
      }
    }
  }
  &__block-4{
    h1{
      margin-bottom: 22px;
      width: 545px;
      span{
        display: inline;
      }
      @media (max-width: 1239px) {
        margin-bottom: 12px;
        width: 440px;
        span{
          display: none;
        }
      }
      @media (max-width: 959px) {
        width: 300px;
      }
    }
    p{
      margin-bottom: 34px;
      width: 410px;

      @media (max-width: 1239px) {
        margin-bottom: 30px;
        width: 330px;
      }
    }
    img{
      margin-top: -3px;
      width: 557px;
      height: 558px;
      @media (max-width: 1239px) {
        margin-top: 0;
        width: 410px;
        height: 400px;
      }
      @media (max-width: 959px) {
        width: 300px;
        height: auto;
        margin-top: auto;
        margin-bottom: -146px;
      }
    }
  }
  &__buttons{
    display: flex;

    button:first-child{
      margin-right: 20px;
    }
    @media (max-width: 959px){
      flex-direction: column;
      button:first-child{
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }

  &__controls{
    position: absolute;
    bottom: 20px;

    @media (max-width: 1239px) {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      bottom: -20px;
      p{
        margin-left: 30px;
      }
    }
    @media (max-width: 959px) {
      display: none;
    }
    p{
      display: inline;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: center;


      span{
        display: inline;
        font-size: 40px;
        font-weight: 400;
        line-height: 48px;
        letter-spacing: 0;
        text-align: center;
      }
    }
  }
  &__controlButtons{
    display: flex;
    margin-top: 11px;
    button:first-child{
      margin-right: 10px;
    }

  }
}