.otherOffers{
  padding-bottom: 142px;
  @media (max-width: 1239px) {
    padding-bottom: 92px;
  }

  &-block{
    padding-bottom: 0;

    .otherOffers__offerRow:last-child{
      border-bottom: 0;
    }
  }
  h2{
    margin-bottom: 51px;
    @media (max-width: 959px) {
      margin-bottom: 34px;
    }
  }
  &__offerRow1{
    h3{
      width: 300px!important;
    }
  }
  &__offerRow2{
    h3{
      width: 260px!important;
    }
  }
  &__offerRow3{
    h3{
      width: 220px!important;
    }
  }
  &__offerRow5{
    h3{
      width: 220px!important;
    }
  }
  &__offerRow{
    padding-top: 23px;
    height: 120px;
    box-sizing: border-box;
    border-top: 1px solid rgba(16, 32, 39, 0.1);
    cursor: pointer;

    @media (max-width: 1239px){
      height: 157px;
    }
    @media (max-width: 959px){
      height: 208px;
    }
    &:last-child{
      border-bottom: 1px solid rgba(16, 32, 39, 0.1);
    }
    .container{
      display: flex;
      @media (max-width: 959px) {
        flex-direction: column;
        position: relative;

        img{
          position: absolute;
          right: 0;
        }
      }
    }
    h3{
      font-weight: 400;
      margin-top: 1px;
      @media (max-width: 1239px){
        width: 390px;
      }
      @media (max-width: 959px){

        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;

      }

    }
    p{
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      opacity: 0.6;
      width: 516px;
      margin-left: auto;
      margin-right: 50px;
      @media (max-width: 1239px){
        margin-right: 20px;
        width: 400px;
        br{
          display: none;
        }
      }
      @media (max-width: 959px){
        width: 300px;
        margin-top: 9px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;

      }
    }
    img{
      height: 24px;
      margin-top: 2px;
      @media (max-width: 959px) {
        margin: 0;
      }
    }
  }
}