.storeMetalsPage{
  &__info{

    h2{
      margin-bottom: 71px;
      @media (max-width: 1239px) {
        margin-bottom: 50px;
      }
    }
  }
}
.storeMetalsPage .callUs{
  margin-top: 72px;
  &__info{

    h2{
      margin-bottom: 71px;
      @media (max-width: 1239px) {
        margin-bottom: 50px;
      }
    }
  }

}