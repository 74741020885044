.faq{
  padding-bottom: 150px;
  @media (max-width: 1239px) {
    padding-bottom: 100px;
  }
  @media (max-width: 959px) {
    padding-bottom: 80px;
  }

  &__h2{
    margin-bottom: 52px;
    @media (max-width: 1239px) {
      margin-bottom: 30px;
    }
    @media (max-width: 959px) {
      margin-bottom: 34px;
    }
  }
  &__row{
    height: 75px;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    padding-top: 24px;
    box-sizing: border-box;
    border-top: 1px solid rgba(16, 32, 39, 0.1);
    transition-duration: 300ms;
    @media (max-width: 959px) {
      height: 94px;
    }
    &:last-child{
      border-bottom: 1px solid rgba(16, 32, 39, 0.1);
    }
    &.big.opened{
      height: 166px;

      @media (max-width: 959px) {
        height: 240px;
      }
    }
    &.opened{
      height: 145px;
      background-color: #F6F8F8;
      border: 0;

      p{
        opacity: 0.6;
      }

      .faq__plus{
        .vertical{
          transform: rotate(90deg);
        }
      }
      @media (max-width: 959px) {
        height: 180px;
      }
    }
    p{
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0;
      text-align: left;
      opacity: 0;
      margin-top: 20px;
      width: 650px;
      transition-duration: 300ms;
      @media (max-width: 959px) {
        width: 300px;
        br{
          display: none;
        }
      }

    }
  }
  &__textRow{
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3{

      font-weight: 400;
      @media (max-width: 959px) {
        width: 250px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;

      }
    }
  }
  &__plus{
    position: relative;
    height: 14px;
    width: 14px;
    .horizontal{
      position: absolute;
      top: 6px;
      left: 0;
      width: 14px;
      height: 2px;
      background-color: #102027;
    }
    .vertical{
      position: absolute;
      left: 6px;
      top: 0;
      width: 2px;
      height: 14px;
      background-color: #102027;
      transition-duration: 300ms;
    }
  }
}