.infoPoints{
  h2{
    margin-bottom: 71px;
    width: 586px;
    @media (max-width: 1239px) {
      margin-bottom: 31px;
    }
    @media (max-width: 959px) {
      width: 300px;
    }
  }

  &__row{
    display: flex;
    justify-content: space-between;
    @media (max-width: 959px) {
      flex-direction: column;
    }
  }
  &__card{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 278px;
    border-top: 1px solid #10202726;
    border-bottom: 1px solid #10202726;
    padding-top: 15px;
    padding-bottom: 40px;
    height: 490px;
    @media (max-width: 1239px) {
      width: 210px;
      height: 467px;
      padding-bottom: 30px;
      &:nth-child(1) p{
        margin-top: 19px;
      }
      &:nth-child(2) p{
        margin-top: 45px;
      }
      &:nth-child(3) p{
        margin-top: 19px;
      }
      &:nth-child(4) p{
        margin-top: 39px;
      }

    }
    @media (max-width: 959px) {
      width: 300px;
      height: auto;
      &:nth-child(4){
        br{
          display: none;
        }
      }
    }

    &-small{
      height: 376px;
      h3{
        @media (max-width: 1239px) {
          br{
            display: none;
          }
        }
      }
      @media (max-width: 969px) {
        height: auto;
      }
    }
    h3.thin{
      margin-bottom: 29px;
      font-weight: 400;
      opacity: 0.5;
      @media (max-width: 1239px) {
        margin-bottom: 19px;
      }
      @media (max-width: 959px) {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 11px;
      }
    }
    h3{
      margin-bottom: 29px;
      @media (max-width: 1239px) {
        margin-bottom: 0;
      }

    }
    p{
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0;
      text-align: left;
      @media (max-width: 959px) {
        margin-top: 10px!important;
      }
    }
    img{
      width: 278px;
      height: 180px;
      border-radius: 8px;
      margin-top: auto;
      @media (max-width: 1239px) {
        width: 210px;
        height: 150px;
        object-fit: cover;
      }
      @media (max-width: 959px) {
        width: 100%;
        height: auto;
        margin-top: 25px;
      }
    }
  }
}