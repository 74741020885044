.header {
    &__form {
        right: -100vw;
        position: fixed;
        background-color: white;
        z-index: 15;
        top: 0;
        height: 100vh;
        width: 785px;
        transition-duration: 300ms;

        &.opened {
            right: 0;
        }
        @media (max-width: 1239px) {
            width: 500px;
        }
        @media (max-width: 959px) {
            width: 100vw;
            background-color: white;

            .callUs {
                background-color: white;
            }
        }
        & > img {
            position: absolute;
            right: 20px;
            top: 20px;
            @media (max-width: 959px) {
                right: calc((100vw - 300px) / 2);
            }
        }
        .callUs .container {
            padding: 0;
            align-items: flex-start;
            background-color: white;
            flex-direction: column;
            max-width: 785px;
            padding-left: 60px;
            padding-top: 64px;

            @media (max-width: 1239px) {
                padding-top: 42px;
                max-width: 500px;
                padding-left: 30px;
            }
            @media (max-width: 959px) {
                max-width: 300px;
                padding-top: 0;
                padding-left: 0;
            }
        }
        .callUs__contact:nth-child(2) {
            margin-bottom: 0;
        }
        .callUs__contacts {
            margin-top: 70px;
            @media (max-width: 1239px) {
                margin-top: 40px;
            }
        }
        .callUs__contacts span {
            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
            letter-spacing: 0;
            text-align: left;
            @media (max-width: 959px) {
                margin-top: 0;
            }
        }
        .callUs__content-2 > p {
            width: auto;
            @media (max-width: 1239px) {
                width: 420px;
                br {
                    display: none;
                }
            }
            @media (max-width: 959px) {
                width: 300px;
            }
        }
        .callUs__submitMessage-2 {
            @media (max-width: 959px) {
                width: 300px;
            }
        }

        .callUs__submitMessage-2 > p {
            width: 431px;

            @media (max-width: 959px) {
                width: 300px;
            }
        }

        .callUs__links {
            margin-top: 34px;
        }
        .rowContacts {
            display: flex;
            .callUs__contact {
                margin-right: 66px;
            }
        }
    }
    &__tabMenuLanguages {
        display: flex;
        margin-top: 13px;
        margin-bottom: 45px;
        @media (max-width: 959px) {
            margin-top: auto;
        }
        button {
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0;
            text-align: left;
            opacity: 0.3;
            margin-right: 15px;
            @media (max-width: 959px) {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0;
                text-align: left;
            }
        }
        button.active {
            opacity: 1;
        }
        button:last-child {
            margin-right: 0;
        }
    }
    &__tabMenuHolder {
        padding-top: 46px;
        padding-left: 30px;
        flex-direction: column;
        align-items: flex-start !important;
        @media (max-width: 959px) {
            height: calc(100% - 102px);
            max-width: 100%;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            padding-top: 30px;
            align-items: flex-start !important;
        }
    }
    &__tabMenuLinks {
        display: flex;
        flex-direction: column;
        @media (max-width: 959px) {
            margin-bottom: 20px;
        }
        a {
            position: relative;
            margin-bottom: 24px;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0;
            text-align: left;
            width: max-content;
            height: auto !important;
            @media (max-width: 959px) {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0;
                text-align: left;
            }
        }
        a::before {
            position: absolute;
            bottom: -4px;
            content: "";
            height: 1px;
            width: 0;
            background-color: #01579b;
            transition-duration: 300ms;
            opacity: 0;
        }
        a:hover:before {
            width: 100%;
            opacity: 1;
        }
    }
    .container__tabMenu {
        display: flex;
        justify-content: space-between;
        height: 70px;
        align-items: center;
        a {
            margin-left: 30px;
        }
        button {
            margin-right: 45px;
        }
        border-bottom: 1px solid #1020271a;
        @media (max-width: 959px) {
            max-width: 100%;
            a {
                margin-left: 20px !important;
            }
            button {
                margin-right: 10px !important;
            }
        }
    }
    &__tabMenu {
        position: fixed;
        top: 0;
        width: 50vw;
        background-color: white;
        height: 100vh;
        box-shadow: -1px 0px 10px rgba(0, 0, 0, 0.25);
        right: -50vw;
        opacity: 0;
        transition-duration: 300ms;
        z-index: 10;
        @media (max-width: 959px) {
            width: 50vw;
            right: -100vw;

            .header__tabMenuLinks {
                order: 1;
            }
            .header__tabMenuHolder > button {
                order: 2;
                width: 300px;
            }
            .header__tabMenuLanguages {
                order: 3;
            }
        }
        @media (max-width: 639px) {
            width: 100vw;
        }

        &.opened {
            right: 0;
            opacity: 1;
        }
    }
    &__logo {
        @media (max-width: 1239px) {
            height: 24px;
        }
    }
    &__gray {
        background-color: #f6f8f8;
    }
    .container {
        display: flex;
        align-items: center;
        a:first-child {
            height: 24px;
        }
    }
    &__firstRow {
        display: flex;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
        border-bottom: 1px solid #1020271a;
        @media (max-width: 1239px) {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    &__links {
        margin-left: 40px;
        z-index: 20;

        @media (min-width: 960px) and (max-width: 1239px) {
            display: none;
        }
        @media (max-width: 959px) {
            display: none;
        }
        &-tab {
            display: none;

            @media (min-width: 960px) and (max-width: 1239px) {
                display: block;
            }
        }

        a {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
            color: #102027;
            position: relative;
            @media (max-width: 1239px) {
                font-size: 16px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0;
                text-align: left;
            }
        }
        a:first-child {
            margin-right: 40px;
        }
        a::before {
            position: absolute;
            bottom: -31.5px;
            content: "";
            height: 1px;
            width: 0;
            background-color: #01579b;
            transition-duration: 300ms;
            opacity: 0;
            @media (max-width: 1239px) {
                bottom: -26.5px;
            }
        }
        a:hover:before {
            width: 100%;
            opacity: 1;
        }
    }
    &__becameClient {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
        color: #102027;
        height: 40px;
        width: 166px;
        border: 1px solid #10202733;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition-duration: 300ms;

        &:hover {
            border: 1px solid #102027;
        }
        @media (max-width: 959px) {
            display: none;
        }
    }
    &__languages {
        margin-left: auto;
        margin-right: 40px;

        button {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
            color: #102027;
            opacity: 0.3;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
            &.active {
                opacity: 1;
            }
        }
        img {
            display: none;
            cursor: pointer;
        }
        @media (max-width: 1239px) {
            margin-right: 20px;
            button {
                display: none;
            }
            img {
                display: block;
                height: 24px;
            }
        }
        @media (max-width: 959px) {
            margin-right: 0;
        }
    }

    &__secondRow {
        padding-top: 29px;
        padding-bottom: 30px;
        border-bottom: 1px solid #1020271a;
        display: flex;
        @media (max-width: 1239px) {
            display: none;
        }
    }
    &__bottomLinks {
        a {
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0;
            text-align: left;
            color: #102027;
            position: relative;
        }
        a:first-child {
            margin-right: 40px;
        }
        a::before {
            position: absolute;
            bottom: -32px;
            content: "";
            height: 1px;
            width: 0;
            background-color: #01579b;
            transition-duration: 300ms;
            opacity: 0;
        }
        a:hover:before {
            width: 100%;
            opacity: 1;
        }
    }
    &__support {
        margin-left: auto;

        a {
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0;
            text-align: left;
            color: #102027;
            position: relative;
        }
        a::before {
            position: absolute;
            bottom: -32px;
            content: "";
            height: 1px;
            width: 0;
            background-color: #01579b;
            transition-duration: 300ms;
            opacity: 0;
        }
        a:hover:before {
            width: 100%;
            opacity: 1;
        }
    }
}
