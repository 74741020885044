.policyPage{

  &__row{
    display: flex;
    margin-top: 52px;
    @media (max-width: 959px) {
      flex-direction: column;
      margin-top: 31px;
    }
  }
  &__secondCol{
    margin-left: 30px;
    @media (max-width: 959px) {
      margin-left: 0;
      margin-top: 22px;
    }
  }
  &__firstCol, &__secondCol{
    div{
      display: flex;
      margin-bottom: 20px;

      &:last-child{
        margin-bottom: 0;
      }
      h3{
        width: 535px;
        @media (max-width: 1239px) {
          width: 390px;

          br{
            display: none;
          }
        }
        @media (max-width: 959px) {
          width: 300px;
          br{
            display: none;
          }
        }
      }
      .line{
        width: 30px;
        height: 1px;
        background-color: #102027;
        opacity: 0.5;
        margin-top: 13px;
        margin-right: 20px;
        @media (max-width: 959px) {
          margin-top: 12px;
          margin-bottom: 0;
        }
      }
    }
  }
  & > .container{
    padding-top: 90px;
    padding-bottom: 146px;
    @media (max-width: 1239px) {
      padding-top: 50px;
      padding-bottom: 94px;
    }
    @media (max-width: 959px) {
      padding-top: 23px;
      padding-bottom: 75px;
    }
    p{
      width: 893px;
      @media (max-width: 959px) {
        width: 300px;
      }
    }
    h1{
      margin-bottom: 40px;
      @media (max-width: 1239px) {
        margin-bottom: 24px;
      }
    }
    h2{
      margin-bottom: 40px;
      @media (max-width: 1239px) {
        margin-bottom: 24px;
      }
    }
    section:nth-child(1){
      p{
        width: auto;
      }
    }

    section{
      margin-bottom: 100px;
      @media (max-width: 1239px) {
        margin-bottom: 86px;
      }
      @media (max-width: 959px) {
        margin-bottom: 69px;
      }

      &:last-child{
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        h3{
          display: inline;
          margin-bottom: 26px;
          @media (max-width: 959px) {
            margin-bottom: 22px;
          }

          &:last-child{
            margin-bottom: 0;
          }
        }
        h3.thin{
          font-weight: 400;
          @media (max-width: 959px) {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }
    }
  }
}