.aboutPage{
  @media (max-width: 1239px) {
    .container{
      p.small{
        width: 360px;

      }
    }

  }
  @media (max-width: 959px) {
    .otherBanner{
      padding-top: 23px!important;

      h1{
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 18px;
      }
      p.small{
        width: 300px;
      }
    }
  }
  &__partners{
    padding-top: 147px;
    padding-bottom: 142px;
    @media(max-width: 1239px){
      padding-top: 95px;
      padding-bottom: 95px;
    }
    @media(max-width: 959px){
      padding-top: 75px;
      padding-bottom: 75px;
    }

    h3.thin{
      opacity: 0.5;
      margin-bottom: 16px;
      @media(max-width: 959px){
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 9px;
      }
    }
    h2{
      width: 893px;
      margin-bottom: 51px;
      @media(max-width: 1239px){
        width: 803px;
        margin-bottom: 31px;
      }
      @media(max-width: 959px){
        width: 270px;
        margin-bottom: 34px;
      }
    }
  }
  &__partnersRow{
    display: flex;
    @media(max-width: 959px){
      flex-direction: column;
      row-gap: 50px;
    }

    img{
      cursor: pointer;
    }
  }
  &__partner{
    cursor: pointer;
    width: 360px;
    background-color: #F6F8F8;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    border-radius: 8px;
    &:nth-child(3) > img{
      height: 60px;
    }
    &:nth-child(1) > img{
      height: 80px;
    }
    @media(max-width: 1239px){
      height: 100px;

      img{
        height: 25px;
      }
    }
    @media(max-width: 959px){
      width: 300px;
      margin-right: 0;

      img{
        padding-top: 28px;
        padding-bottom: 28px;
        background-color: #F6F8F8;
        border-radius: 8px;
        margin-bottom: 10px;
      }
      &:nth-child(1) > img{
        padding-left: calc(50% - 67px);
        padding-right: calc(50% - 67px);
      }
      &:nth-child(2) > img{
        padding: 55px calc(50% - 64px);
      }
      &:nth-child(3) > img{
        padding-left: calc(50% - 83px);
        padding-right: calc(50% - 83px);
        padding-top: 43px;
        padding-bottom: 43px;
        height: 50px;
      }
    }
    &:last-child{
      margin-right: 0;
      @media(max-width: 959px){
        margin-bottom: 0;
      }
    }
  }
  &__ceo{
    background-color:  #F6F8F8;
    padding-top: 42px;
    @media(max-width: 1239px){
      padding-top: 34px;
    }

    .container{
      display: flex;

      @media (max-width: 959px) {
        flex-direction: column;
      }
    }
  }
  &__ceoImg{
    width: 584px;
    @media(max-width: 1239px){
      width: 462px;
    }
    @media(max-width: 959px){
      width: 300px;
      margin-top: 30px;
    }
  }
  &__ceoText{
    p{
      margin-top: 51px;
      width: 614px;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0;
      text-align: left;
      margin-bottom: 47px;
      @media(max-width: 1239px){
        margin-bottom: 27px;
        margin-top: 9px;
      }
      @media (max-width: 959px){
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;
        width: 300px;
        margin-top: 0;
        margin-bottom: 21px;
      }
    }
    h3{
      margin-bottom: 8px;
      @media(max-width: 1239px){
        margin-bottom: 3px;
      }
      @media (max-width: 959px){
        margin-bottom: 6px;
      }
    }
    label{
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0;
      text-align: left;
    }
    @media (max-width: 959px){

    }
  }
  &__location{
    padding-bottom: 150px;
    padding-top: 142px;
    @media(max-width: 1239px){
      padding-top: 92px;
      padding-bottom: 100px;
    }
    @media (max-width: 959px) {
      padding-top: 75px;
      padding-bottom: 80px;
    }
  }
  &__row{
    display: flex;
    justify-content: space-between;
    @media (max-width: 959px) {
      flex-direction: column;
    }

    p{
      width: 585px;
      margin-bottom: 27px;
      @media(max-width: 1239px){
        width:420px;
        margin-bottom: 18px;
      }
      @media (max-width: 959px) {
        width: 300px;
        margin-top: 28px;
      }
    }
  }
  &__map{
    width: 1200px;
    margin-top: 74px;
    @media(max-width: 1239px){
      width:900px;
      margin-top: 44px;
    }
    @media (max-width: 959px) {
      margin-top: 34px;
      width: 300px;
      height: 250px;
      object-fit: contain;
      background-color: #F6F8F8;
      border-radius: 8px;
    }
  }
}